import React from 'react';
import styled from 'styled-components';
import { color, mediaQuery } from '../../styles/variables';

const BasicPrice = () => {
  return (
    <Container id={'priceTable-basic'}>
      <Heading>カウンセリング料</Heading>
      <Row>
        <CellLeft>カウンセリング料（初診）（VISIA込み）</CellLeft>
        <CellRight>
          <IncludedTax>3,850円</IncludedTax>
        </CellRight>
      </Row>
      <Row>
        <CellLeft>カウンセリング料（再診）</CellLeft>
        <CellRight>
          <IncludedTax>1,650円</IncludedTax>
        </CellRight>
      </Row>
      <Annotation>
        <p>※VISIAを用いた肌診断は、四谷院以外でご案内可能です。</p>
        <p>※同じ施術メニューで2回目以降のご受診の際には、カウンセリング費用は不要です。</p>
      </Annotation>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 65rem;
  margin: 2rem auto 18.75rem;
  padding: 0 1rem;
`;

const Heading = styled.h3`
  width: 100%;
  font-size: 1.4rem;
  padding: 0.8rem;
  font-weight: 400;
  background-color: ${color.pink};
  text-align: center;

  @media screen and ${mediaQuery.mobile} {
    font-size: 1rem;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${color.grey[200]};
  padding: 1rem 3rem;
  text-align: center;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.9rem;
    padding: 1rem;
  }
`;

const CellLeft = styled.div`
  flex: 0 0 auto;
  @media screen and ${mediaQuery.mobile} {
    flex: auto;
  }
`;

const CellRight = styled.div`
  flex: 0 0 16rem;
  @media screen and ${mediaQuery.mobile} {
    flex: 0 0 7rem;
  }
`;

const IncludedTax = styled.div`
  font-size: 1.2rem;
  @media screen and ${mediaQuery.mobile} {
    font-size: 1.1rem;
  }
`;

const Annotation = styled.div`
  margin: 1.5rem 0 0;
  font-size: 0.75rem;
`;

export default BasicPrice;
