import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

type Props = {
  children: React.ReactNode;
  direction: 'toRight' | 'toLeft' | 'toTop';
};

const toTopAnimation = {
  variants: {
    enter: { y: 120, opacity: 0 },
    center: { y: 0, opacity: 1 },
  },
  transition: {
    opacity: { duration: 1, ease: 'easeOut' },
    y: { duration: 1.2, ease: [0.19, 0.97, 0.45, 0.99] },
  },
};

const toRightAnimation = {
  variants: {
    enter: { x: -120, opacity: 0 },
    center: { x: 0, opacity: 1 },
  },
  transition: {
    opacity: { duration: 1, ease: 'easeOut' },
    x: { duration: 1.2, ease: [0.19, 0.97, 0.45, 0.99] },
  },
};

const toLeftAnimation = {
  variants: {
    enter: { x: 120, opacity: 0 },
    center: { x: 0, opacity: 1 },
  },
  transition: {
    opacity: { duration: 1, ease: 'easeOut' },
    x: { duration: 1.2, ease: [0.19, 0.97, 0.45, 0.99] },
  },
};

const FadeIn: React.VFC<Props> = ({ children, direction }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.7,
  });

  const animationSetting = () => {
    switch (direction) {
      case 'toLeft':
        return toLeftAnimation;
      case 'toRight':
        return toRightAnimation;
      case 'toTop':
        return toTopAnimation;
      default:
        return toTopAnimation;
    }
  };

  // 要素が画面に入ったらアニメーションをスタートさせる
  useEffect(() => {
    if (inView) controls.start('center');
  }, [inView, controls]);

  return (
    <div ref={ref}>
      <motion.div initial={'enter'} animate={controls} variants={animationSetting().variants} transition={animationSetting().transition}>
        {children}
      </motion.div>
    </div>
  );
};

export default FadeIn;
