import React from 'react';
import styled from 'styled-components';

import { color, mediaQuery } from '../../styles/variables';
import AvailableClinic from './AvailableClinic';

const CosmeticsTable = () => {
  return (
    <Container id={'priceTable-101'}>
      <TitleContainer>
        <h3>医療機関専売コスメ</h3>
      </TitleContainer>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TreatmentHead>施術名</TreatmentHead>
              <AvailableClinicHead>対象院</AvailableClinicHead>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ゼオスキンヘルス</td>
              <td>
                <AvailableList>
                  <AvailableClinic shimbashi={true} tamachi={true} yotsuya={true} shinsaibashi={true} ikebukuro={true} />
                </AvailableList>
              </td>
            </tr>
            <tr>
              <td>プラスリストア</td>
              <td>
                <AvailableList>
                  <AvailableClinic shimbashi={true} tamachi={true} yotsuya={true} shinsaibashi={true} ikebukuro={true} />
                </AvailableList>
              </td>
            </tr>
            <tr>
              <td>スキンピールバー</td>
              <td>
                <AvailableList>
                  <AvailableClinic shimbashi={true} tamachi={true} yotsuya={true} shinsaibashi={true} ikebukuro={true} />
                </AvailableList>
              </td>
            </tr>
          </tbody>
        </Table>
      </TableContainer>
      <Annotation>
        <p>※ゼオスキンヘルスは初回購入の際に、カウンセリングが必須となります。</p>
      </Annotation>
    </Container>
  );
};

const Container = styled.div`
  margin: 6rem auto 0;
  width: 100%;
  max-width: 65rem;
`;

const TitleContainer = styled.div`
  padding: 0 1rem;

  > h3 {
    width: 100%;
    font-size: 1.4rem;
    padding: 0.8rem;
    font-weight: 400;
    background-color: ${color.pink};
    text-align: center;

    @media screen and ${mediaQuery.mobile} {
      font-size: 1rem;
    }
  }
`;

const TableContainer = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 0 1rem;

  @media screen and ${mediaQuery.tablet} {
    padding: 0 1rem;
  }
`;

const Table = styled.table`
  width: 100%;
  min-width: 42rem;
  table-layout: fixed;
  height: 1px; // tdの子要素で高さを継承させるため、文字サイズ以下の任意の数字が入っていればいい
  text-align: center;
  margin: 2rem 0 0;
  border-spacing: 0;
  border-collapse: collapse;

  > thead {
    th {
      border-bottom: 1px solid ${color.grey[200]};
      font-size: 0.9rem;
      padding: 0.5rem 0;
    }

    th + th {
      border-left: 1px solid ${color.grey[200]};
    }
  }

  > tbody {
    tr {
      border-bottom: 1px solid ${color.grey[200]};
      height: 5rem;
    }

    td {
      padding: 0;
      height: 100%;
    }

    td + td {
      border-left: 1px solid ${color.grey[200]};
    }
  }
`;

const TreatmentHead = styled.th`
  text-indent: -9999px;
`;

const AvailableClinicHead = styled.th`
  width: 5.6rem;
`;

const AvailableList = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  height: 7rem;
  display: flex;
  align-items: center;

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Annotation = styled.div`
  text-align: center;
  margin: 3rem 0 0;
  padding: 0 2rem;
  font-size: 0.9rem;

  > p {
    padding: 1rem 0;
    border-top: 2px solid ${color.grey[900]};
    border-bottom: 2px solid ${color.grey[900]};
  }
`;

export default CosmeticsTable;
