import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../styles/variables';
import { StaticImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';

const AboutConcept = () => {
  const isMobile = useMediaQuery({
    query: mediaQuery.mobile,
  });
  return (
    <Container>
      <MainText>
        健康的なうつくしさを実感するには、
        <br />
        充実した日々が大事だと考えます。
        <br />
        だから、わたしたちは美容のかかりつけクリニックを目指しました。
        <br />
        環境や生活リズムの変化で現れるトラブルに、
        {isMobile && <br />}
        それぞれ最適なこたえを。
        <br />
        トラブルになる前に相談できるように。
        <br />
        そして、一人ひとりのライフスタイルを大切に。
      </MainText>
      <SubText>キレイな肌、健康なカラダ、うつくしい毎日を。</SubText>
      <ConceptImage>
        <StaticImage src={'../../images/top/concept.png'} alt={'院内で施術　オンライン診療　ホームケア'} />
      </ConceptImage>
      <MainText>
        美容皮膚科のクリニックとして、院内での施術はもちろん、
        <br />
        オンライン診療での内服薬・外用薬の処方、ドクターズコスメのホームケアなど、
        <br />
        さまざまなステージの悩みをトータルでサポートします。
      </MainText>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  padding: 0 1rem;
  margin-top: 4rem;

  @media screen and ${mediaQuery.mobile} {
    margin-top: 2rem;
  }
`;

const MainText = styled.p`
  font-size: 1rem;
  line-height: 2.8;
  letter-spacing: 0.1em;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.75rem;
  }
`;

const SubText = styled.p`
  font-size: 1.1rem;
  letter-spacing: 0.1em;
  margin-top: 0.5rem;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.8rem;
  }
`;

const ConceptImage = styled.div`
  width: 75%;
  max-width: 30rem;
  margin: 3rem auto;
`;

export default AboutConcept;
