import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { mediaQuery } from '../../styles/variables';
import styled from 'styled-components';
import PageHeading from '../common/PageHeading';
import { DoctorsTextSVG } from '../common/TextSVG';

const AboutDoctors = () => {
  return (
    <Container>
      <PageHeading>
        <DoctorsTextSVG />
      </PageHeading>
      <Subhead>CLINIC FORの美容医療を支えるドクターたち。</Subhead>
      <ImageContainer>
        <StaticImage src={'../../images/top/doctors_image.jpg'} alt={''} />
      </ImageContainer>
      <Text>
        内科・皮膚科・美容皮膚科など、
        <br />
        幅広い領域で経験と施術実績を持つドクターやスタッフが複数在籍しています。
        <br />
        患者さんに合わせ、パーソナライズした施術をご提案いたします。
      </Text>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: 8rem;
`;

const Subhead = styled.p`
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
`;

const ImageContainer = styled.div`
  max-width: 56rem;
  width: 100%;
  margin: 4rem auto 0;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 2.4;
  text-align: center;
  padding: 0 1rem;
  margin-top: 4rem;
  letter-spacing: 0.1em;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.8rem;
    margin-top: 2rem;
  }
`;

export default AboutDoctors;
