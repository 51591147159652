import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import TopTreatmentList from './TopTreatmentList';
import { mediaQuery } from '../../styles/variables';
import FadeIn from '../common/FadeIn';
import { TreatmentTextSVG } from '../common/TextSVG';

const TopTreatmentSection = () => {
  return (
    <Container>
      <Heading>
        <TreatmentTextSVG />
      </Heading>
      <Description>
        肌のトラブルや、エイジングケアなど、美容皮膚科ならではの最先端の技術・機器で
        <br />
        エビデンスに基づいた施術を専門の医療スタッフがご対応いたします。
      </Description>
      <SectionImage>
        <FadeIn direction={'toTop'}>
          <StaticImage src="../../images/top/top_treatment_image.jpg" alt="" />
        </FadeIn>
      </SectionImage>
      <SubHeading>施術別で探す</SubHeading>
      <TopTreatmentList />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 14rem 0 0;

  @media screen and (max-width: 60rem) {
    margin: 8rem 0 0;
  }
`;

const Description = styled.p`
  text-align: center;
  font-size: 1rem;
  margin: 2rem auto 0;
  padding: 0 2rem;
  line-height: 2;
  letter-spacing: 0.1em;
  font-feature-settings: 'palt' 1;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.9rem;
  }
`;

const SectionImage = styled.div`
  width: 100%;
  max-width: 70rem;
  margin: 3rem auto 0;
`;

const Heading = styled.h2`
  width: 32rem;
  margin: 0 auto;
  padding: 0 2rem;

  > svg {
    width: 100%;
  }

  @media screen and ${mediaQuery.mobile} {
    width: 19rem;
  }
`;

const SubHeading = styled.p`
  text-align: center;
  margin: 6rem 0 0;
  font-size: 2.4rem;
  letter-spacing: 0.3em;

  @media screen and ${mediaQuery.mobile} {
    margin: 4rem 0 0;
    font-size: 1.2rem;
  }
`;

export default TopTreatmentSection;
