import React from 'react';
import styled from 'styled-components';
import { color, mediaQuery } from '../../styles/variables';
import { Link } from 'gatsby';
import LogoClinicFor from './LogoClinicFor';

const Footer = () => (
  <Container>
    <Legal>
      <li>
        <Link to={'/privacy'}>プライバシーポリシー</Link>
      </li>
      <li>
        <Link to={'/under_age'}>18歳未満の方へ</Link>
      </li>
    </Legal>
    <LogoContainer>
      <a href="https://www.clinicfor.life/">
        <LogoClinicFor />
      </a>
    </LogoContainer>
    <License>Copyright © 2019-2021 CLINIC FOR GROUP All rights reserved.︎</License>
  </Container>
);

const Container = styled.footer`
  background-color: ${color.grey[200]};
  text-align: center;
  padding: 4rem 0;
  width: 100%;
`;

const Legal = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  width: 30rem;
  margin: 0 auto;
  font-size: 1rem;
  list-style: none;

  > li {
    > a {
      color: ${color.grey[900]};
      text-decoration: none;

      &:hover {
        color: ${color.grey[800]};
      }
    }
  }

  @media screen and ${mediaQuery.mobile} {
    width: 100%;
    max-width: 20rem;
    font-size: 0.7rem;
  }
`;

const LogoContainer = styled.div`
  width: 10rem;
  margin: 2rem auto 0;

  @media screen and ${mediaQuery.mobile} {
    width: 8rem;
  }
`;

const License = styled.p`
  font-size: 0.7rem;
  margin: 2rem 0 0;
  padding: 0;
`;

export default Footer;
