import React from 'react';
import styled from 'styled-components';
import { color, mediaQuery } from '../../../styles/variables';
import { MenuTreatmentProps } from './TreatmentList';

type Props = Pick<MenuTreatmentProps, 'contentId' | 'steps'>;

const Steps: React.VFC<Props> = ({ contentId, steps }) => {
  return (
    <>
      <Container>
        {steps.content.map((step, i) => (
          <li key={`Steps-${contentId}-${i}`}>
            <IndexContainer>{i + 1}</IndexContainer>
            <StepContent>{step}</StepContent>
          </li>
        ))}
      </Container>
      {steps.annotation && <Annotation>{steps.annotation}</Annotation>}
    </>
  );
};

const Container = styled.ul`
  list-style: none;
  display: flex;
  margin: 1rem 0 0;
  padding: 0;

  > li {
    flex: 1;
    padding: 1rem;
    background-color: ${color.beige};
    position: relative;
  }

  > li + li {
    margin-left: 0.2rem;
  }

  @media screen and ${mediaQuery.tablet} {
    display: block;

    > li {
      display: flex;
      align-items: center;
      padding: 0.5rem;
    }

    > li + li {
      margin-left: 0;
      margin-top: 0.2rem;
    }
  }
`;

const StepContent = styled.p`
  font-size: 0.75rem;

  @media screen and ${mediaQuery.tablet} {
    margin-left: 1rem;
  }
`;

const IndexContainer = styled.div`
  position: absolute;
  top: -0.4rem;
  left: -0.4rem;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.8rem;
  border-radius: 50%;
  background-color: ${color.grey[900]};
  border: 3px solid ${color.white};
  color: ${color.white};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and ${mediaQuery.tablet} {
    position: static;
    border: none;
  }
`;

const Annotation = styled.p`
  font-size: 0.75rem;
  margin-top: 0.2rem;
`;

export default Steps;
