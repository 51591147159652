import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { color, mediaQuery } from '../../../styles/variables';

const TroubleIndex = () => {
  const isLaptop = useMediaQuery({
    query: mediaQuery.laptop,
  });
  return (
    <Container>
      <IndexContainer>
        <IndexHead>
          お顔・肌の
          {!isLaptop && <br />}
          悩み
        </IndexHead>
        <List>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-1'}>美白・くすみ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-2'}>ニキビ・ニキビ跡</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-3'}>肌質改善</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-4'}>色素沈着</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-5'}>肝斑・シミ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-6'}>ホクロ・イボ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-7'}>毛穴</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-8'}>小顔</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-9'}>シワ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-10'}>たるみ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-11'}>クマ</AnchorLink>
          </li>
        </List>
      </IndexContainer>
      <IndexContainer>
        <IndexHead>
          身体の悩み
          {!isLaptop && <br />}
          その他
        </IndexHead>
        <List>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-12'}>肥満症治療</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-13'}>発毛/薄毛</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-14'}>疲労回復</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-15'}>多汗症</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-16'}>低用量ピル</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-17'}>ドクターズコスメ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-18'}>男性向け</AnchorLink>
          </li>
        </List>
      </IndexContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 3rem auto 0;
  padding: 0 1rem;
`;

const IndexContainer = styled.div`
  display: flex;
  margin: 4rem 0 0;

  @media screen and ${mediaQuery.laptop} {
    display: block;
  }
`;

const IndexHead = styled.div`
  padding: 1rem;
  background-color: ${color.pink};
  flex: 0 0 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  flex: 1;
  display: grid;
  margin: 0 0 0 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;

  > li {
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      height: 100%;
      background-color: ${color.grey[100]};
      padding: 1rem 0.5rem;
      color: ${color.grey[900]};

      &:hover {
        background-color: ${color.pink};
      }
    }
  }

  @media screen and ${mediaQuery.laptop} {
    margin: 0.5rem 0 0 0;
    grid-template-columns: 1fr 1fr;
    font-size: 0.8rem;
  }
`;

export default TroubleIndex;
