import React from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseIcon } from '../Icon';
import { navigate } from 'gatsby';
import { color, font } from '../../../styles/variables';
import Overlay from './Overlay';
import NavigationLogo from './NavigationLogo';
import NavigationLink from './NavigationLink';
// eslint-disable-next-line import/named
import { OverlayTriggerState } from '@react-stately/overlays';
import { AriaButtonProps } from '@react-types/button';
import { ReservationLinks } from '../../common/ReservationLinks';

type Props = {
  state: OverlayTriggerState;
  closeButtonRef: React.MutableRefObject<null>;
  closeButtonProps: AriaButtonProps;
};

const NavigationSP: React.VFC<Props> = ({ state, closeButtonProps, closeButtonRef }) => {
  const onNavigate = async (path: string) => {
    await navigate(path);
    state.close();
  };
  return (
    <>
      <AnimatePresence>
        {state.isOpen && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2, delay: 0.05 }}>
            <Overlay isOpen onClose={state.close} isDismissable>
              <OverlayMenuHeader>
                <NavigationLink onPress={() => onNavigate('/')}>
                  <NavigationLogo />
                </NavigationLink>
                <ToggleButton {...closeButtonProps} ref={closeButtonRef}>
                  <CloseIcon size={24} />
                </ToggleButton>
              </OverlayMenuHeader>
              <OverlayNavigation>
                <div className="menu-items">
                  <div className="menu">
                    <div className="main">MENU</div>
                    <div className="subs">
                      <a href="/menu/treat" className="main" onClick={() => state.close()}>
                        施術別で探す
                      </a>
                      <a href="/menu/trouble" className="main" onClick={() => state.close()}>
                        お悩みで探す
                      </a>
                    </div>
                  </div>
                  <a href="/price" className="link" onClick={() => state.close()}>
                    PRICE
                  </a>
                  <a href="/access" className="link" onClick={() => state.close()}>
                    ACESS
                  </a>
                  <a href="/about" className="link" onClick={() => state.close()}>
                    ABOUT
                  </a>
                  <a href="/contact" className="link" onClick={() => state.close()}>
                    CONTACT
                  </a>
                </div>
                <ReservationLinks />
              </OverlayNavigation>
            </Overlay>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const OverlayMenuHeader = styled.div`
  width: 100%;
  padding: 0.3rem 1rem;
  background-color: ${color.white};
  display: flex;
  align-items: center;
`;

const ToggleButton = styled.button`
  margin-left: auto;
  border: none;
  background-color: ${color.white};
  transition: 0.2s;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${color.grey[200]};
  }
`;

const OverlayNavigation = styled.nav`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 60px;
  gap: 24px;
  font-family: Helvetica Neue;
  @media screen and (max-height: 800px) {
    gap: 0px;
    padding-top: 30px;
  }
  .menu-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    @media screen and (max-height: 800px) {
      gap: 20px;
    }
    .menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      .main {
        font-size: 18px;
        @media screen and (max-height: 800px) {
          font-size: 16px;
        }
        font-weight: 300;
        text-decoration: none;
        color: black;
        letter-spacing: 2px;
      }
      .subs {
        display: flex;
        flex-direction: column;
        gap: 16px;
        a {
          font-size: 14px;
          letter-spacing: 1px;
        }
        @media screen and (max-height: 800px) {
          gap: 12px;
          a {
            font-size: 12px;
          }
        }
      }
    }
    .link {
      font-size: 18px;
      font-weight: 300;
      text-decoration: none;
      color: black;
      font-family: Helvetica Neue;
      letter-spacing: 2px;

      @media screen and (max-height: 800px) {
        font-size: 16px;
      }
    }
  }
  #reservation-title {
    @media screen and (max-height: 800px) {
      display: none;
    }
  }
  #reservation-link {
    @media screen and (max-height: 800px) {
      height: 30px;
      font-size: 12px;
    }
  }
`;

export default NavigationSP;
