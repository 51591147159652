import React, { useState } from 'react';
import styled from 'styled-components';
import { color, mediaQuery } from '../../../styles/variables';
import { AddIcon, RemoveIcon } from '../../common/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { MenuTreatmentProps } from './TreatmentList';

type Props = MenuTreatmentProps['faq'][number];

const FAQItem: React.VFC<Props> = ({ question, answer, annotation }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Container onClick={() => setExpanded(!expanded)}>
      <Question>
        <QuestionHead>
          <p>Q</p>
        </QuestionHead>
        <QuestionContent>
          <p>{question}</p>
          <ExpandIconContainer>{expanded ? <RemoveIcon size={24} /> : <AddIcon size={24} />}</ExpandIconContainer>
        </QuestionContent>
      </Question>
      <AnimatePresence initial={false}>
        {expanded && (
          <>
            <Answer
              key={'content'}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ ease: 'easeOut' }}
            >
              <AnswerHead>A</AnswerHead>
              <AnswerContent>
                <p>{answer}</p>
                {annotation && <AnswerAnnotation>{annotation}</AnswerAnnotation>}
              </AnswerContent>
            </Answer>
          </>
        )}
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Question = styled.div`
  display: flex;
`;

const QuestionHead = styled.div`
  padding: 0.5rem 0;
  font-size: 1.4rem;
  flex: 0 0 3rem;
  background-color: ${color.grey[200]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionContent = styled.div`
  font-size: 0.9rem;
  background-color: ${color.grey[100]};
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.8rem;
  }
`;

const ExpandIconContainer = styled.div`
  flex: 0 0 24px;
  margin-left: 0.5rem;
`;

const Answer = styled(motion.div)`
  display: flex;
  margin: 0.5rem 0 0;
`;

const AnswerHead = styled.div`
  flex: 0 0 3rem;
  height: 3rem;
  font-size: 1.4rem;
  background-color: ${color.pink};
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AnswerContent = styled.div`
  font-size: 1rem;
  margin-top: 0.8rem;
  flex: 1;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
`;

const AnswerAnnotation = styled.p`
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

export default FAQItem;
