export const color = {
  white: '#fff',
  black: '#000',
  grey: {
    900: '#251e1c',
    800: '#565656',
    200: '#e7e8e8',
    100: '#f4f4f4',
  },
  pink: '#e5c8c5',
  lightPink: '#ede0df',
  beige: '#e6e3d8',
  red: '#e43726',
};

export const mediaQuery = {
  mobile: `(max-width: 40rem)`,
  tablet: `(max-width: 48rem)`,
  laptop: `(max-width: 60rem)`,
};

export const font = {
  sansSerif: "-apple-system, BlinkMacSystemFont, 'Noto Sans JP', sans-serif",
};
