import React from 'react';
import styled from 'styled-components';
import TopHero from '../components/top/TopHero';
// import TopNews from '../components/top/TopNews';
import MedicalTreatmentMenu from '../components/top/MedicalTreatmentMenu';
import TopConcept from '../components/top/TopConcept';
import TopSearchSection from '../components/top/TopSearchSection';
import TopTreatmentSection from '../components/top/TopTreatmentSection';
import TopVoiceSection from '../components/top/TopVoiceSection';
import TopDoctorsSection from '../components/top/TopDoctorsSection';
import FloatingButton from '../components/common/FloatingButton';
import { useInView } from 'react-intersection-observer';
import { Meta } from '../components/common/Meta';
import InstagramBanner from '../components/top/InstagramBanner';

const IndexPage = () => {
  const [ref, inHeroView] = useInView({});
  return (
    <Container>
      <Meta />
      <div ref={ref}>
        <TopHero />
      </div>
      <MedicalTreatmentMenu />
      <TopConcept />
      <TopSearchSection />
      <TopTreatmentSection />
      {/* 
      ・ステマ規制法を考慮して、Voiceは一旦非表示にする。
      ・背景: https://lincwell.slack.com/archives/C018EB1G56D/p1696214174655059
      <TopVoiceSection />
      */}
      <TopDoctorsSection />
      {/* newsがないときは非表示にする*/}
      {/* <TopNews />*/}
      <InstagramBanner />
      <FloatingButton visible={!inHeroView} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 0 0 8rem;
`;

export default IndexPage;
