import React from 'react';
import styled from 'styled-components';
import TreatmentBody from './TreatmentBody';
import TreatmentDetail from './TreatmentDetail';
import FAQ from './FAQ';
import { color } from '../../../styles/variables';
import Steps from './Steps';
import Introduction from './Introduction';
import { MenuTreatmentProps } from './TreatmentList';
import TreatmentLinkButton from './TreatmentLinkButton';

const TreatmentListItem: React.VFC<MenuTreatmentProps> = ({
  contentId,
  name,
  description,
  category,
  link,
  steps,
  introduction,
  trouble,
  feature,
  time,
  time2,
  effect,
  faq,
}) => {
  return (
    <Container id={`treatment-${contentId}`}>
      <Heading>{category}</Heading>
      <TreatmentBody contentId={contentId} name={name} description={description} trouble={trouble} />
      <TreatmentDetail contentId={contentId} feature={feature} time={time} effect={effect} time2={time2} />
      {steps && <Steps contentId={contentId} steps={steps} />}
      {introduction && <Introduction contentId={contentId} introduction={introduction} />}
      {faq && <FAQ contentId={contentId} faq={faq} />}
      {link && <TreatmentLinkButton link={link} />}
    </Container>
  );
};

const Container = styled.div`
  margin: 0 0 9.375rem;
`;

const Heading = styled.h3`
  background-color: ${color.pink};
  width: 100%;
  padding: 1rem 0;
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.2em;
  font-weight: 400;
`;

export default TreatmentListItem;
