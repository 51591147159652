import React from 'react';
import styled from 'styled-components';
import TopCarouselMenu from './TopCarouselMenu';
import { color } from '../../styles/variables';
import { useStaticQuery, graphql } from 'gatsby';

const MedicalTreatmentMenu = () => {
  const data = useStaticQuery(graphql`
    query Carousel {
      image: allFile(filter: { relativeDirectory: { eq: "carouselImages" } }, sort: { fields: relativePath }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      content: allFile(filter: { name: { eq: "top_carousel_menu" } }) {
        edges {
          node {
            childrenTopContentYaml {
              id
              content {
                head
                link
                description
                option {
                  name
                  price
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Container>
      <TopCarouselMenu data={data} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${color.beige};
  padding: 1rem;
  overflow: hidden;
`;

export default MedicalTreatmentMenu;
