import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
} & IconProps;

type IconProps = {
  size: number;
  color?: string;
};

const Icon: React.VFC<Props> = (props) => <IconContainer {...props}>{props.children}</IconContainer>;

export const MenuIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  </Icon>
);

export const CloseIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>
  </Icon>
);

export const PrevIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
    </svg>
  </Icon>
);

export const NextIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
    </svg>
  </Icon>
);

export const ChevronRightIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
    </svg>
  </Icon>
);

export const ChevronBottomIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  </Icon>
);

export const ReservationIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path d="M55.4,4.91H8.58A3.65,3.65,0,0,0,5,8.55V44.09a3.66,3.66,0,0,0,3.63,3.65H55.4A3.65,3.65,0,0,0,59,44.09V8.55A3.66,3.66,0,0,0,55.4,4.91ZM54.21,36.47a2.44,2.44,0,0,1-2.43,2.43H12.22a2.43,2.43,0,0,1-2.43-2.43V12.06A2.43,2.43,0,0,1,12.2,9.61H51.78A2.45,2.45,0,0,1,54.21,12Z" />
      <path d="M41.86,56.71c1,1.32.52,2.38-1.15,2.38H23.27c-1.66,0-2.18-1.06-1.14-2.38L27,51H37Z" />
    </svg>
  </Icon>
);

export const ShoppingIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path d="M59.43,15.38a2.15,2.15,0,0,0-1.69-.82H17.37l-1-4.83A2.19,2.19,0,0,0,14.17,8H6.26a2.19,2.19,0,0,0,0,4.37h6.17l7,32a2.17,2.17,0,0,0,2.13,1.71H51.18a2.18,2.18,0,0,0,0-4.36H23.35l-.58-2.64H53.28a2.18,2.18,0,0,0,2.14-1.72l4.45-20.12A2.18,2.18,0,0,0,59.43,15.38ZM51.56,34.69H21.81L18.35,18.94H55Z" />
      <path d="M26.44,48.29a3.85,3.85,0,1,0,3.85,3.85v-.06A3.86,3.86,0,0,0,26.44,48.29Z" />
      <circle cx="47.3" cy="52.14" r="3.85" />
    </svg>
  </Icon>
);

export const PriceIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path d="M56.7,6.2a8.94,8.94,0,0,0-6.6,5.2c-5.9,8.1-8.9,12.6-11.8,17h17c.7,0,.7,3,0,3H36.1v8.1H55.3c.7,0,.7,3,0,3H36.1V54.3a1.92,1.92,0,0,0,2.2,2.2c1.5.7,3.7.7,4.4,1.5a.7.7,0,1,1,0,1.4H24.2c-.7,0-.7,0-.7-.7a.69.69,0,0,1,.7-.7,13.36,13.36,0,0,0,4.4-1.5,2.34,2.34,0,0,0,2.2-2.2v-12H10.2c-.7,0-.7-3,0-3H30.1V31.2H10.2c-.7,0-.7-3,0-3H28.7c-5.2-6.6-8.9-11.8-14.8-18.5A12.87,12.87,0,0,0,8,6a.69.69,0,0,1-.7-.7V4.7H23.5a.69.69,0,0,1,.7.7c0,.7,0,0-.7.7-3,.7-5.2,1.5-3.7,3,4.4,5.2,10.3,12.6,14.8,18.5h0c3-4.4,7.4-10.3,11.1-16.2,2.2-2.2.7-3.7-3.7-5.2-.7,0-.7,0-.7-.7a.69.69,0,0,1,.7-.7H56c.7.6.7.6.7,1.4Z" />
    </svg>
  </Icon>
);

export const AccessIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path d="M59.65,17.08H52.93V10.2H11.05v6.88H4.35V53.8h55.3Zm-1.72,35H38.17V39.68a2.49,2.49,0,0,0-.2-.62c-.06-.2,0-.36-.16-.55a6.25,6.25,0,0,0-5.69-3.77h0A6.15,6.15,0,0,0,26,40.91v11.2H6.07V18.79h6.69V11.91H51.24v6.88h6.69Z" />
      <rect x="46.46" y="23.19" width="5.95" height="5.95" />
      <rect x="11.57" y="23.19" width="5.95" height="5.95" />
      <rect x="29.02" y="23.19" width="5.95" height="5.95" />
      <rect x="46.46" y="38.25" width="5.95" height="5.95" />
      <rect x="11.57" y="38.25" width="5.95" height="5.95" />
    </svg>
  </Icon>
);

export const AfterCareIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        fill="none"
        d="M46.16,45.07H44.35V51a.52.52,0,0,0,.52.52H57.63a.52.52,0,0,0,.52-.52h0V29.59a.52.52,0,0,0-.52-.51H49.19v13A3.06,3.06,0,0,1,46.16,45.07Z"
      />
      <path fill="none" d="M45.15,35.7V29.08h-.28a.52.52,0,0,0-.52.51h0v7.67A2,2,0,0,0,45.15,35.7Z" />
      <path
        fill="none"
        d="M43.13,26.6h2V15.35a2,2,0,0,0-2-2h-33A2,2,0,0,0,8.1,15.24a.41.41,0,0,0,0,.11V35.7a2,2,0,0,0,2,2H42.61V27.12A.52.52,0,0,1,43.13,26.6Z"
      />
      <path d="M30.82,47.78H22.5l-4,4.78c-.88,1.09-.44,2,.95,2H33.85c1.39,0,1.83-.89,1-2Z" />
      <path d="M59.35,26.6H49.19V12.43a3,3,0,0,0-3-3h-39a3,3,0,0,0-3,3V42.16a3.05,3.05,0,0,0,3,2.91H42.61v9a.52.52,0,0,0,.52.52H59.35a.52.52,0,0,0,.52-.52h0v-27A.52.52,0,0,0,59.35,26.6Zm-16.74.52v10.6H10.17a2,2,0,0,1-2-2V15.35a2,2,0,0,1,2-2h33a2,2,0,0,1,2,2V26.6h-2A.52.52,0,0,0,42.61,27.12ZM58.13,50.89a.52.52,0,0,1-.52.52H44.87a.52.52,0,0,1-.52-.4V29.59a.52.52,0,0,1,.52-.51H57.63a.52.52,0,0,1,.52.51h0Z" />
    </svg>
  </Icon>
);

export const TwitterIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
      <g>
        <circle cx="200" cy="200" r="200" />
      </g>
      <g>
        <path
          fill="#fff"
          d="M163.4,305.5c88.7,0,137.2-73.5,137.2-137.2c0-2.1,0-4.2-0.1-6.2c9.4-6.8,17.6-15.3,24.1-25
		c-8.6,3.8-17.9,6.4-27.7,7.6c10-6,17.6-15.4,21.2-26.7c-9.3,5.5-19.6,9.5-30.6,11.7c-8.8-9.4-21.3-15.2-35.2-15.2
		c-26.6,0-48.2,21.6-48.2,48.2c0,3.8,0.4,7.5,1.3,11c-40.1-2-75.6-21.2-99.4-50.4c-4.1,7.1-6.5,15.4-6.5,24.2
		c0,16.7,8.5,31.5,21.5,40.1c-7.9-0.2-15.3-2.4-21.8-6c0,0.2,0,0.4,0,0.6c0,23.4,16.6,42.8,38.7,47.3c-4,1.1-8.3,1.7-12.7,1.7
		c-3.1,0-6.1-0.3-9.1-0.9c6.1,19.2,23.9,33.1,45,33.5c-16.5,12.9-37.3,20.6-59.9,20.6c-3.9,0-7.7-0.2-11.5-0.7
		C110.8,297.5,136.2,305.5,163.4,305.5"
        />
      </g>
    </svg>
  </Icon>
);

export const InstagramIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373 373">
      <circle cx="186.5" cy="186.5" r="186.5" />
      <g>
        <path
          fill="#fff"
          d="M186.5,92.72c30.55,0,34.16.12,46.23.67,11.15.51,17.21,2.37,21.24,3.94a37.86,37.86,0,0,1,21.73,21.72c1.57,4,3.43,10.1,3.94,21.25.55,12.06.67,15.68.67,46.23s-.12,34.16-.67,46.23c-.51,11.15-2.37,17.21-3.94,21.24A37.93,37.93,0,0,1,254,275.71c-4,1.57-10.09,3.43-21.24,3.94-12.06.55-15.68.67-46.23.67s-34.17-.12-46.23-.67c-11.15-.51-17.21-2.37-21.24-3.94A37.84,37.84,0,0,1,97.32,254c-1.57-4-3.43-10.09-3.94-21.24-.55-12.07-.67-15.68-.67-46.23s.12-34.16.67-46.23c.51-11.15,2.37-17.21,3.94-21.24a37.84,37.84,0,0,1,21.73-21.73c4-1.57,10.09-3.43,21.24-3.94,12.07-.55,15.68-.67,46.23-.67m0-20.61c-31.07,0-35,.13-47.17.69s-20.51,2.51-27.79,5.34a58.34,58.34,0,0,0-33.43,33.45c-2.85,7.28-4.79,15.6-5.34,27.77s-.69,16.1-.69,47.17.13,35,.69,47.17,2.49,20.49,5.32,27.77a58.51,58.51,0,0,0,33.45,33.46c7.27,2.82,15.6,4.76,27.77,5.31s16.1.69,47.17.69,35-.13,47.17-.69,20.49-2.49,27.77-5.31a58.58,58.58,0,0,0,33.46-33.46c2.82-7.27,4.76-15.6,5.32-27.77s.68-16.1.68-47.17-.13-35-.68-47.17-2.5-20.49-5.32-27.77a58.4,58.4,0,0,0-33.46-33.43c-7.28-2.85-15.6-4.79-27.77-5.34s-16.1-.69-47.17-.69Z"
        />
        <path
          fill="#fff"
          d="M186.5,127.76a58.75,58.75,0,1,0,58.75,58.75h0a58.76,58.76,0,0,0-58.74-58.75Zm0,96.88a38.15,38.15,0,1,1,38.15-38.14h0A38.15,38.15,0,0,1,186.5,224.64Z"
        />
        <circle fill="#fff" cx="247.57" cy="125.44" r="13.73" />
      </g>
    </svg>
  </Icon>
);

export const LineIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320">
      <g>
        <circle cx="160" cy="160" r="160" />
        <path
          fill="#fff"
          d="M266.7,150.68C266.7,102.89,218.79,64,159.89,64S53.08,102.89,53.08,150.68c0,42.85,38,78.74,89.33,85.52,3.48.75,8.21,2.29,9.41,5.27,1.07,2.7.7,6.93.34,9.65,0,0-1.25,7.54-1.52,9.15-.47,2.7-2.15,10.56,9.25,5.76s61.51-36.22,83.92-62h0C259.29,187,266.7,169.81,266.7,150.68Z"
        />
        <path d="M231.17,178.28a2,2,0,0,0,2-2v-7.57a2,2,0,0,0-2-2H210.78v-7.87h20.39a2,2,0,0,0,2-2v-7.58a2,2,0,0,0-2-2H210.78v-7.87h20.39a2,2,0,0,0,2-2v-7.58a2,2,0,0,0-2-2h-30a2,2,0,0,0-2,2v0h0V176.2h0v0a2,2,0,0,0,2,2h30Z" />
        <path d="M120.17,178.28a2,2,0,0,0,2-2v-7.57a2,2,0,0,0-2-2H99.77v-37a2,2,0,0,0-2-2H90.16a2,2,0,0,0-2,2V176.2h0v0a2,2,0,0,0,2,2h30Z" />
        <rect x="128.62" y="127.58" width="11.65" height="50.69" rx="2.04" />
        <path d="M189.8,127.58h-7.57a2,2,0,0,0-2,2v27.69l-21.33-28.8a1.2,1.2,0,0,0-.17-.21v0l-.12-.12,0,0-.11-.09-.06,0-.1-.08-.07,0-.11-.06-.06,0-.12,0-.07,0-.12,0-.08,0-.12,0h-.08l-.11,0h-7.72a2,2,0,0,0-2,2v46.62a2,2,0,0,0,2,2h7.58a2,2,0,0,0,2-2V148.56l21.35,28.84a2,2,0,0,0,.53.51l0,0,.13.08.06,0,.1.05.1,0,.06,0,.15,0h0a2.35,2.35,0,0,0,.54.07h7.53a2,2,0,0,0,2-2V129.62A2,2,0,0,0,189.8,127.58Z" />
      </g>
    </svg>
  </Icon>
);

export const MapIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    </svg>
  </Icon>
);

export const AddIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </svg>
  </Icon>
);

export const RemoveIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 13H5v-2h14v2z" />
    </svg>
  </Icon>
);

export const ClinicIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.01 34">
      <circle fill="#e7372f" cx="17" cy="17" r="17" />
      <path
        fill="#fff"
        d="M15,18.84H8.8a.1.1,0,0,0-.1.1V20a.1.1,0,0,0,.1.1h5V25a.11.11,0,0,0,.11.1H15a.11.11,0,0,0,.11-.1V18.94A.11.11,0,0,0,15,18.84Zm10.2-8.66a.11.11,0,0,0,.1-.1V9a.11.11,0,0,0-.1-.1H13.9a.11.11,0,0,0-.11.1v4.88h-5a.1.1,0,0,0-.1.1v1.1a.1.1,0,0,0,.1.1H15a.11.11,0,0,0,.11-.1V10.18h3.77v4.88a.11.11,0,0,0,.1.1h6.22a.11.11,0,0,0,.1-.1V14a.11.11,0,0,0-.1-.1h-5V10.18Zm0,8.66H19a.11.11,0,0,0-.1.1V25a.11.11,0,0,0,.1.1h1.12a.1.1,0,0,0,.1-.1V20.14h5a.11.11,0,0,0,.1-.1v-1.1A.11.11,0,0,0,25.21,18.84Z"
      />
    </svg>
  </Icon>
);

export const HomeIcon: React.VFC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.01 34">
      <path fill="#e7372f" d="M33.87,14.79A17,17,0,1,1,19.22.14,17,17,0,0,1,33.87,14.79Z" />
      <path
        fill="#fff"
        d="M27.16,16.89H24.3V24H21.8V16.89H17.87V24H10V16.89H7.16L10,14V8h2.85v3.22l4.29-4.29ZM15.73,20.46V16.89H12.16v3.57Z"
      />
    </svg>
  </Icon>
);

const IconContainer = styled.div<IconProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  > svg {
    width: 100%;
    fill: ${(props) => props.color};
  }
`;
