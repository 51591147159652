import React from 'react';
import styled from 'styled-components';
import TreatmentListItem from './TreatmentListItem';
import { graphql, useStaticQuery } from 'gatsby';

export type MenuTreatmentProps = {
  contentId: string;
  name: string;
  category: string;
  link: string;
  description: string[];
  trouble: string[];
  feature: string[];
  time: {
    main: string;
    iconType: number;
    option: string;
    condition: string;
    annotation: string;
  };
  time2: {
    main: string;
    iconType: number;
  };
  effect: {
    centeredText: string;
    content: {
      head: string;
      text: string;
    }[];
    interval: string;
    annotation: string;
  };
  interval: string;
  steps: {
    content: string[];
    annotation: string;
  };
  introduction: {
    head: string;
    content: string;
    whiteningContent: {
      firstLine: string;
      list: string[];
      secondLine: string;
    };
  }[];
  faq: {
    question: string;
    answer: string;
    annotation: string;
  }[];
};

const TreatmentList = () => {
  const TreatmentData = useStaticQuery(graphql`
    query MenuTreatment {
      allTreatmentContentYaml(sort: { order: ASC, fields: contentId }) {
        nodes {
          contentId
          name
          category
          link
          description
          trouble
          feature
          effect {
            centeredText
            annotation
            interval
            content {
              head
              text
            }
          }
          faq {
            annotation
            answer
            question
          }
          introduction {
            head
            content
            whiteningContent {
              firstLine
              list
              secondLine
            }
          }
          steps {
            annotation
            content
          }
          time {
            annotation
            condition
            iconType
            main
            option
          }
          time2 {
            main
            iconType
          }
        }
      }
    }
  `);
  const data = TreatmentData.allTreatmentContentYaml.nodes;
  console.log({data})
  return (
    <Container>
      {data.map((content: MenuTreatmentProps, i: number) => (
        <TreatmentListItem key={`TreatmentListItem-${i}`} {...content} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 60rem;
  padding: 0 1rem;
  margin: 18.75rem auto 0;
`;

export default TreatmentList;
