import React from 'react';
import './layout.scss';
import GlobalNavigation from './common/navigation/GlobalNavigation';
import Footer from './common/Footer';
import styled from 'styled-components';

const Layout: React.FC = ({ children }) => {
  return (
    <Container>
      <GlobalNavigation />
      {children}
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

export default Layout;
