import React from 'react';
import styled from 'styled-components';
import MenuList from '../../components/menu/treatment/MenuList';
import TreatmentList from '../../components/menu/treatment/TreatmentList';
import { Meta } from '../../components/common/Meta';
import MenuHeading from '../../components/menu/MenuHeading';
import HeadingFullWidth from '../../components/menu/HeadingFullWidth';
import FloatingButton from '../../components/common/FloatingButton';

const MenuPage = () => {
  return (
    <Container>
      <Meta title={'施術別メニュー'} />
      <MenuHeading />
      <HeadingFullWidth text={'施術別で探す'} />
      <MenuList />
      <TreatmentList />
      <FloatingButton visible={true} />
    </Container>
  );
};

const Container = styled.div`
  margin: 8rem 0;
`;

export default MenuPage;
