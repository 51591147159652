import React from 'react';
import styled from 'styled-components';
import { ChevronRightIcon } from './Icon';
import { color } from '../../styles/variables';

const ViewMoreButton: React.VFC = () => (
  <Container>
    VIEW MORE
    <ButtonContainer>
      <ChevronRightIcon size={24} />
    </ButtonContainer>
  </Container>
);

const Container = styled.div`
  position: relative;
  display: inline-block;
  padding: 0.5rem 4rem;
  border: 1px solid ${color.black};
  font-size: 1rem;
  text-decoration: none;
  color: ${color.black};
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
`;

export default ViewMoreButton;
