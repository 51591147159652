import React from 'react';
import styled from 'styled-components';

const Logo = () => {
  return (
    <Container>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.21 94.2">
        <path
          fill="#e83827"
          d="M95.27,57.89H59.17a.68.68,0,0,0-.64.64v35a.68.68,0,0,0,.64.64h6.41a.68.68,0,0,0,.64-.64V65.36H95.05a.68.68,0,0,0,.65-.64V58.31C95.91,58.1,95.48,57.89,95.27,57.89Zm-58.74,0H.64a.56.56,0,0,0-.64.64v6.41a.68.68,0,0,0,.64.64H29.48v28a.68.68,0,0,0,.64.64h6.41a.68.68,0,0,0,.64-.64v-35A.57.57,0,0,0,36.53,57.89ZM95.27,7.69a.68.68,0,0,0,.64-.64V.64A.68.68,0,0,0,95.27,0H30.12a.67.67,0,0,0-.64.64v28H.64a.69.69,0,0,0-.64.64v6.41a.67.67,0,0,0,.64.64H36.53a.68.68,0,0,0,.64-.64v-28H59v28a.67.67,0,0,0,.64.64H95.27a.68.68,0,0,0,.64-.64V29.26a.68.68,0,0,0-.64-.64H66.43V7.26H95.27Z"
        />
        <path d="M147.17,23.71a10.63,10.63,0,0,1-3.63,7.9c-2.13,1.93-5.12,2.78-9,2.78a12.19,12.19,0,0,1-6.83-1.92c-1.93-.86-3.42-2.78-4.7-4.91s-1.5-4.49-1.5-7.69v-3A22.49,22.49,0,0,1,123,9a11,11,0,0,1,4.7-5.13,12.91,12.91,0,0,1,7-1.92,15.24,15.24,0,0,1,8.54,3A11.05,11.05,0,0,1,147,12.82h-5.55c-.22-2.14-.86-4.06-2.14-4.92a8.24,8.24,0,0,0-4.7-1.49A6,6,0,0,0,129,9.19c-1.28,1.92-1.92,4.27-1.92,7.68v2.78c0,3.42.64,6.2,1.92,7.69a6,6,0,0,0,5.55,2.78,8.33,8.33,0,0,0,4.91-1.5c.86-.85,1.5-2.35,1.93-4.91Z" />
        <path d="M165.76,29.69h14.52V34H160V2.56h5.77Z" />
        <path d="M198.65,34H193.1V2.56h5.55Z" />
        <path d="M239.24,34h-5.77L219,11.75V34h-5.56V2.56H219l14.52,22.22V2.56H239V34Z" />
        <path d="M259.74,34h-5.55V2.56h5.55Z" />
        <path d="M299.05,23.71a10.71,10.71,0,0,1-3.63,7.9c-2.14,1.93-5.13,2.78-9,2.78a12.73,12.73,0,0,1-6.84-1.92,8.9,8.9,0,0,1-4.27-4.91,19.6,19.6,0,0,1-1.5-7.69v-3a22.49,22.49,0,0,1,1.5-7.9A11,11,0,0,1,280,3.84a12.91,12.91,0,0,1,7-1.92c3.63,0,6.4.86,8.54,2.78a11,11,0,0,1,3.63,7.9h-5.55c-.22-2.13-.86-4.06-2.14-4.91a8.24,8.24,0,0,0-4.7-1.5A6,6,0,0,0,281.32,9c-1.28,1.92-1.92,4.27-1.92,7.69v2.78c0,3.42.64,6.19,1.92,7.69a6,6,0,0,0,5.55,2.77,8.46,8.46,0,0,0,4.92-1.49c1.28-.85,1.92-2.35,2.13-4.91h4.91v.21Z" />
        <path d="M349.25,20.51H336.43V34h-5.55V2.56h20.29V6.84H336.43v9.39h12.82Z" />
        <path d="M389.4,19a26.32,26.32,0,0,1-1.49,8.33,17.21,17.21,0,0,1-4.7,5.56c-1.92,1.49-4.27,1.92-7,1.92a13.48,13.48,0,0,1-7.05-1.92,12.15,12.15,0,0,1-4.7-5.13,21.8,21.8,0,0,1-1.49-7.9V17.52a26.32,26.32,0,0,1,1.49-8.33,15.17,15.17,0,0,1,4.7-5.56,12.42,12.42,0,0,1,7.05-1.92,12.94,12.94,0,0,1,7,1.92,12.1,12.1,0,0,1,4.7,5.13,18,18,0,0,1,1.49,7.9Zm-5.55-1.49c0-3.42-.64-6.2-2.14-7.91A6.87,6.87,0,0,0,376,6.84a6,6,0,0,0-5.56,2.77,13.92,13.92,0,0,0-2.13,7.91v1.92c0,3.42.64,6.19,2.13,7.9a7.38,7.38,0,0,0,11.54,0c1.28-1.92,1.92-4.48,1.92-7.9V17.52Z" />
        <path d="M414.82,21.79h-6.19V34h-5.56V2.56h11.32a14.52,14.52,0,0,1,8.55,2.35c2.14,1.5,3,4.06,3,7.05a8.57,8.57,0,0,1-1.5,5.13c-.85,1.49-2.56,2.35-4.27,3.42l7,13v.21h-5.77Zm-6-4.27h5.77a6,6,0,0,0,4.27-1.5,5.13,5.13,0,0,0,1.5-3.63,5.84,5.84,0,0,0-1.5-4.06,6,6,0,0,0-4.27-1.49h-5.77Z" />
        <path d="M123.46,88V56.61h11.11c3.63,0,6.41.64,8.33,2.13a7.58,7.58,0,0,1,2.78,6.41,5.78,5.78,0,0,1-1.28,4.06A7.47,7.47,0,0,1,141,72,7,7,0,0,1,145,74.76a8.09,8.09,0,0,1,1.49,4.49c0,3-.85,5.12-2.77,6.62s-4.7,2.35-8.33,2.35h-12ZM129,69.64h5.55c1.92,0,3-.22,4.06-1.29a4.65,4.65,0,0,0,1.5-3.41,3.74,3.74,0,0,0-1.29-3.21c-.85-.64-2.56-.85-4.27-.85H129Zm0,4v9.83h6.19c1.92,0,3-.21,4.06-1.28a5.15,5.15,0,0,0,1.5-3.63c0-3.42-1.93-4.92-5.13-4.92Z" />
        <path d="M178.79,73.91H166v9.82h15.38V88H160.42V56.61h20.93v4.27H166v8.54h13.25v4.49Z" />
        <path d="M211.9,80.74H199.51L197.16,88h-5.77l12-31.4h4.92L220.65,88h-5.76ZM200.79,76H210l-4.69-13Z" />
        <path d="M255.47,56.61V77.75a9.83,9.83,0,0,1-3.42,7.91,12.59,12.59,0,0,1-8.54,3,14.05,14.05,0,0,1-9-2.78C232,84,231.12,81.38,231.12,78V56.82h5.56V78a8.42,8.42,0,0,0,1.49,4.91c1.28,1.28,2.78,1.49,4.91,1.49,4.28,0,6.41-2.13,6.41-6.62V57h5.77v-.42Z" />
        <path d="M292.64,60.88h-10V88h-5.55V60.88h-9.83V56.61h25.42Z" />
        <path d="M315.5,71.56l7.26-15H329L318.27,76.47V88h-5.55V76.47L302,56.61h6.19Z" />
      </svg>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  > svg {
    width: 100%;
  }
`;

export default Logo;
