import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { color, mediaQuery } from '../../styles/variables';
import FadeIn from '../common/FadeIn';
import { SearchTextSVG } from '../common/TextSVG';

const TopSearchSection: React.VFC = () => {
  return (
    <Container>
      <Heading>
        <SearchTextSVG />
      </Heading>
      <Description>
        肌のトラブルや、エイジングケアなど、これまでの多数の症例や経験、エビデンスを元に
        <br />
        一人ひとりに合わせた治療法をご提案し、さまざまな悩みにお応えしています。
      </Description>
      <SubHeading>お悩み・症状から探す</SubHeading>
      <FaceTrouble>
        <FaceTroubleImage>
          <FadeIn direction={'toRight'}>
            <StaticImage src="../../images/top/top_search_face.jpg" alt="" width={520} height={480} />
          </FadeIn>
          <FaceTroubleHead>
            <p>
              お顔・肌の
              <br />
              悩み
            </p>
          </FaceTroubleHead>
        </FaceTroubleImage>
        <FaceTroubleList>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-1'}>美肌・美白</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-2'}>ニキビ・ニキビ跡</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-3'}>肌質改善</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-4'}>色素沈着</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-5'}>肝斑・シミ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-6'}>ホクロ・イボ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-7'}>毛穴</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-8'}>小顔</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-9'}>シワ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-10'}>たるみ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-11'}>クマ</AnchorLink>
          </li>
        </FaceTroubleList>
      </FaceTrouble>
      <BodyTrouble>
        <BodyTroubleImage>
          <FadeIn direction={'toLeft'}>
            <StaticImage src="../../images/top/top_search_body.jpg" alt="" width={520} height={480} />
          </FadeIn>
          <BodyTroubleHead>
            <p>
              身体の悩み
              <br />
              その他
            </p>
          </BodyTroubleHead>
        </BodyTroubleImage>
        <BodyTroubleList>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-12'}>肥満症治療</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-13'}>発毛・育毛</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-14'}>疲労回復</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-15'}>多汗症</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-16'}>低用量ピル</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-17'}>ドクターズコスメ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/trouble#trouble-18'}>男性向け</AnchorLink>
          </li>
        </BodyTroubleList>
      </BodyTrouble>
      <SectionImage>
        <FadeIn direction={'toTop'}>
          <StaticImage src="../../images/top/top_search_image.jpg" alt="" />
        </FadeIn>
      </SectionImage>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 14rem auto 0;
  overflow: hidden;

  @media screen and ${mediaQuery.mobile} {
    margin: 8rem auto 0;
  }
`;

const Heading = styled.h2`
  width: 23rem;
  margin: 0 auto;
  padding: 0 2rem;

  > svg {
    width: 100%;
  }

  @media screen and ${mediaQuery.mobile} {
    width: 14rem;
  }
`;

const Description = styled.p`
  text-align: center;
  font-size: 1rem;
  line-height: 2;
  padding: 0 2rem;
  margin: 2rem 0 0;
  letter-spacing: 0.1em;
  font-feature-settings: 'palt' 1;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.9rem;
  }
`;

const SubHeading = styled.p`
  font-size: 2.4rem;
  padding: 0 1rem;
  text-align: center;
  margin: 4rem auto 0;
  letter-spacing: 0.3em;

  @media screen and ${mediaQuery.mobile} {
    font-size: 1.2rem;
  }
`;

const FaceTrouble = styled.div`
  width: 100%;
  max-width: 54rem;
  height: 52rem;
  margin: 4rem auto 0;
  display: flex;
  justify-content: space-between;

  @media screen and ${mediaQuery.mobile} {
    max-width: 24rem;
    display: block;
    height: auto;
  }
`;

const FaceTroubleImage = styled.div`
  flex: 1;
  position: relative;
  padding-top: 2rem;
  padding-right: 6rem;

  @media screen and ${mediaQuery.mobile} {
    margin-right: 2rem;
  }
`;

const FaceTroubleHead = styled.div`
  width: 12rem;
  height: 12rem;
  background-color: rgba(229, 200, 197, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.2em;
  font-size: 1.4rem;

  @media screen and ${mediaQuery.mobile} {
    width: 8rem;
    height: 8rem;
    font-size: 1rem;
  }
`;

const FaceTroubleList = styled.ul`
  position: relative;
  flex: 0 0 12rem;
  top: 14rem;
  right: 4rem;
  display: grid;
  list-style: none;
  grid-auto-rows: 3rem;
  padding: 0;
  margin: 0;
  font-size: 1.1rem;

  > li {
    display: flex;
    align-items: center;

    &::before {
      display: block;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid ${color.black};
      padding-right: 0.5rem;
      content: '';
    }

    > a {
      text-decoration: none;
      color: ${color.grey[900]};
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  @media screen and ${mediaQuery.mobile} {
    padding: 0 1rem;
    font-size: 1rem;
    margin: 2rem 0 0;
    top: auto;
    right: auto;
    grid-template-columns: 1fr 1fr;
  }
`;

const BodyTrouble = styled.div`
  position: relative;
  width: 100%;
  max-width: 54rem;
  height: 34rem;
  display: flex;
  flex-direction: row-reverse;
  margin: 0 auto;

  @media screen and ${mediaQuery.mobile} {
    max-width: 24rem;
    display: block;
    height: auto;
    margin: 2rem auto 0;
  }
`;

const BodyTroubleImage = styled.div`
  flex: 1;
  position: relative;
  padding-top: 2rem;
  padding-left: 6rem;

  @media screen and ${mediaQuery.mobile} {
    margin-left: 2rem;
  }
`;

const BodyTroubleHead = styled.div`
  width: 12rem;
  height: 12rem;
  position: absolute;
  background-color: rgba(229, 200, 197, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  font-size: 1.4rem;
  letter-spacing: 0.2em;
  text-align: center;

  @media screen and ${mediaQuery.mobile} {
    width: 8rem;
    height: 8rem;
    font-size: 1rem;
  }
`;

const BodyTroubleList = styled.ul`
  position: relative;
  flex: 0 0 12rem;
  top: 14rem;
  left: 5rem;
  display: grid;
  list-style: none;
  grid-auto-rows: 3.5rem;
  padding: 0;
  margin: 0;
  font-size: 1.1rem;

  > li {
    display: flex;
    align-items: center;

    &::before {
      display: block;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid ${color.black};
      padding-right: 0.5rem;
      content: '';
    }

    > a {
      color: ${color.grey[900]};
      text-decoration: none;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  @media screen and ${mediaQuery.mobile} {
    padding: 0 1rem;
    font-size: 1rem;
    margin: 2rem 0 0;
    top: auto;
    left: auto;
    grid-template-columns: 1fr 1fr;
  }
`;

const SectionImage = styled.div`
  width: 100%;
  max-width: 70rem;
  margin: 8rem auto 0;

  @media screen and ${mediaQuery.mobile} {
    margin: 4rem auto 0;
  }
`;

export default TopSearchSection;
