import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import Logo from '../common/Logo';
import TopConceptCard from './TopConceptCard';
import { color, font, mediaQuery } from '../../styles/variables';

const TopConcept: React.VFC = () => (
  <Container>
    <LogoContainer>
      <Logo />
    </LogoContainer>
    <Text>ライフスタイルに合わせた、「通いやすい」「相談しやすい」環境で健康的なカラダ作りをサポートします。</Text>
    <TopConceptCard />
    <Text>
      美容皮膚科のクリニックとして、院内での施術はもちろん、
      <br />
      オンライン診療での内服薬・外用薬の処方、ドクターズコスメでのホームケアなど、
      <br />
      さまざまなステージの悩みをトータルでサポートします。
    </Text>
    <ConceptImage>
      <StaticImage src={'../../images/top/concept.png'} alt={'院内で施術　オンライン診療　ホームケア'} />
    </ConceptImage>
  </Container>
);

const Container = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 14rem auto 0;

  @media screen and ${mediaQuery.mobile} {
    margin: 8rem auto 0;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  max-width: 32rem;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Text = styled.p`
  font-size: 1.2rem;
  line-height: 2;
  margin: 3rem 0 2rem;
  padding: 0 2rem;
  text-align: center;
  letter-spacing: 0.15em;
  font-feature-settings: 'palt' 1;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.9rem;
  }
`;

const ConceptImage = styled.div`
  width: 75%;
  max-width: 30rem;
  margin: 0 auto;
`;

export default TopConcept;
