import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { color, mediaQuery } from '../../styles/variables';
import { MapIcon, ReservationIcon } from '../common/Icon';
import OutlinedButton from '../common/OutlinedButton';

const AccessClinic = () => {
  return (
    <Container>
      <ClinicContainer>
        <ImageContainer>
          <StaticImage src="../../images/access/clinic_shimbashi.jpg" alt="" />
        </ImageContainer>
        <TextContainer>
          <ClinicHead>クリニックフォア新橋</ClinicHead>
          <ClinicSubhead>〈皮膚科・美容皮膚科・内科・アレルギー科・トラベルクリニック〉</ClinicSubhead>
          <BusinessHours>
            <h3>診療時間</h3>
            <p>【平日】午前9:30-14:30/午後16:00-18:00/夜間18:00-21:00</p>
            <p>【土日祝】午前9:00-13:00/午後14:00-18:00</p>
          </BusinessHours>
          <Address>〒105-0004 東京都港区新橋1丁目4番5号 ヒューリックG10ビル 9F/10F</Address>
          <GoogleMapLink href="https://www.google.com/maps/place/%E3%82%AF%E3%83%AA%E3%83%8B%E3%83%83%E3%82%AF%E3%83%95%E3%82%A9%E3%82%A2%E6%96%B0%E6%A9%8B/@35.667948,139.7568846,17z/data=!3m1!4b1!4m5!3m4!1s0x60188bc008a0356b:0xa9ea5b5446024bce!8m2!3d35.667948!4d139.7590733">
            <MapIcon size={20} />
            <p>Google Map</p>
          </GoogleMapLink>
          <ReservationLink href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_3&department=cosmetic_dermatology">
            <OutlinedButton label={'Webで今すぐ予約申し込み'}>
              <ReservationIcon size={32} />
            </OutlinedButton>
          </ReservationLink>
        </TextContainer>
      </ClinicContainer>

      <ClinicContainer direction={'reverse'}>
        <ImageContainer>
          <StaticImage src="../../images/access/clinic_tamachi.jpg" alt="" />
        </ImageContainer>
        <TextContainer>
          <ClinicHead>クリニックフォア田町</ClinicHead>
          <ClinicSubhead>〈皮膚科・美容皮膚科・内科・アレルギー科・トラベルクリニック〉</ClinicSubhead>
          <BusinessHours>
            <h3>診療時間</h3>
            <p>【平日】午前9:30-14:30/午後16:00-21:00</p>
            <p>【土日祝】午前9:00-13:00/午後14:00-18:00</p>
          </BusinessHours>
          <Address>〒108-0023 東京都港区芝浦3-1-32なぎさテラス4F（「4F-A」と記載のあるビル一番奥の区画です）</Address>
          <GoogleMapLink href="https://goo.gl/maps/a513z72CjdE8T1C66">
            <MapIcon size={20} />
            <p>Google Map</p>
          </GoogleMapLink>
          <ReservationLink href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_1&department=cosmetic_dermatology">
            <OutlinedButton label={'Webで今すぐ予約申し込み'}>
              <ReservationIcon size={32} />
            </OutlinedButton>
          </ReservationLink>
        </TextContainer>
      </ClinicContainer>

      <ClinicContainer>
        <ImageContainer>
          <StaticImage src="../../images/access/clinic_ikebukuro.jpg" alt="" />
        </ImageContainer>
        <TextContainer>
          <ClinicHead>クリニックフォア池袋</ClinicHead>
          <ClinicSubhead>〈皮膚科・美容皮膚科・内科・アレルギー科・トラベルクリニック〉</ClinicSubhead>
          <BusinessHours>
            <h3>診療時間</h3>
            <p>【平日】午前10:30-14:00/午後15:30-20:00</p>
            <p>【土日祝】午前9:00-13:00/午後14:00-18:00</p>
          </BusinessHours>
          <Address>〒171-0022東京都豊島区南池袋1丁目27番10号 油木第一ビル 2F</Address>
          <GoogleMapLink href="https://www.google.com/maps/place/%E3%82%AF%E3%83%AA%E3%83%8B%E3%83%83%E3%82%AF%E3%83%95%E3%82%A9%E3%82%A2%E6%B1%A0%E8%A2%8B/@35.7292646,139.710849,17z/data=!3m1!4b1!4m5!3m4!1s0x60188bafa387a8a9:0x575719c213b27ec5!8m2!3d35.7292603!4d139.7130377">
            <MapIcon size={20} />
            <p>Google Map</p>
          </GoogleMapLink>
          <ReservationLink href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_138&department=cosmetic_dermatology">
            <OutlinedButton label={'Webで今すぐ予約申し込み'}>
              <ReservationIcon size={32} />
            </OutlinedButton>
          </ReservationLink>
        </TextContainer>
      </ClinicContainer>

      <ClinicContainer direction={'reverse'}>
        <ImageContainer>
          <StaticImage src="../../images/access/clinic_yotsuya.jpg" alt="" />
        </ImageContainer>
        <TextContainer>
          <ClinicHead>クリニックフォア四谷</ClinicHead>
          <ClinicSubhead>〈皮膚科・美容皮膚科・アレルギー科・トラベルクリニック〉</ClinicSubhead>
          <BusinessHours>
            <h3>診療時間</h3>
            <p>【平日】午前11:00-14:00/午後15:00-18:00/夜間18:00-20:00 ※火曜日を除く</p>
            <p>【日】午前9:00-13:00/午後14:00-18:00</p>
          </BusinessHours>
          <Address>〒160-0004 東京都新宿区四谷1丁目6番1号 CO・MO・RE Mall 2F</Address>
          <GoogleMapLink href="https://www.google.com/maps?ll=35.687158,139.729321&z=16&t=m&hl=en&gl=JP&mapclient=embed&cid=10284196365702946350">
            <MapIcon size={20} />
            <p>Google Map</p>
          </GoogleMapLink>
          <ReservationLink href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_8&department=cosmetic_dermatology">
            <OutlinedButton label={'Webで今すぐ予約申し込み'}>
              <ReservationIcon size={32} />
            </OutlinedButton>
          </ReservationLink>
        </TextContainer>
      </ClinicContainer>

      <ClinicContainer>
        <ImageContainer>
          <StaticImage src="../../images/access/clinic_shinsaibashi.jpg" alt="" />
        </ImageContainer>
        <TextContainer>
          <ClinicHead>クリニックフォア心斎橋PARCO</ClinicHead>
          <ClinicSubhead>〈皮膚科・美容皮膚科・アレルギー科〉</ClinicSubhead>
          <BusinessHours>
            <h3>診療時間</h3>
            <p>【平日】午前11:00-14:00/午後15:00-18:00/夜間18:00-20:00</p>
            <p>【土日祝】午前10:00-14:00/午後15:00-19:00</p>
          </BusinessHours>
          <Address>〒542-0085 大阪府大阪市中央区心斎橋筋１丁目８−３ 心斎橋PARCO 10階</Address>
          <PhoneNumber>TEL:050-4560-2715</PhoneNumber>
          <GoogleMapLink href="https://www.google.com/maps/place/%E3%82%AF%E3%83%AA%E3%83%8B%E3%83%83%E3%82%AF%E3%83%95%E3%82%A9%E3%82%A2%E5%BF%83%E6%96%8E%E6%A9%8BPARCO/@34.6738473,135.5009574,15z/data=!4m2!3m1!1s0x0:0x8206375747a17730?sa=X&ved=2ahUKEwjJlovspJz0AhWTfd4KHS10DAYQ_BJ6BAhSEAU">
            <MapIcon size={20} />
            <p>Google Map</p>
          </GoogleMapLink>
          <ReservationLink href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_72&department=cosmetic_dermatology">
            <OutlinedButton label={'Webで今すぐ予約申し込み'}>
              <ReservationIcon size={32} />
            </OutlinedButton>
          </ReservationLink>
        </TextContainer>
      </ClinicContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 70rem;
  margin: 4rem auto 0;

  @media screen and ${mediaQuery.laptop} {
    margin: 2rem auto 0;
  }
`;

const ClinicContainer = styled.div<{ direction?: 'reverse' }>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.direction === 'reverse' ? 'row-reverse' : '')};

  @media screen and ${mediaQuery.laptop} {
    display: block;
  }
`;

const ImageContainer = styled.div`
  width: 50%;
  height: 27rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    height: 100%;
  }

  @media screen and ${mediaQuery.laptop} {
    width: 100%;
    height: 18rem;
  }
`;

const TextContainer = styled.div`
  width: 50%;
  padding: 2rem;
  background-color: ${color.grey[200]};

  @media screen and ${mediaQuery.laptop} {
    width: 100%;
    padding: 2rem 1rem;
  }
`;

const ClinicHead = styled.h2`
  font-size: 1.7rem;
  font-weight: 400;
  margin: 0;
  padding: 0;

  @media screen and ${mediaQuery.laptop} {
    text-align: center;
    font-size: 1.6rem;
  }
`;

const ClinicSubhead = styled.p`
  font-size: 0.8rem;

  @media screen and ${mediaQuery.laptop} {
    text-align: center;
  }
`;

const BusinessHours = styled.div`
  margin: 1rem 0 0;

  > h3 {
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0 0 0.4rem;
    position: relative;
    display: flex;
    align-items: center;

    ::before {
      content: '';
      flex: 0 0 16px;
      width: 16px;
      height: 16px;
      margin-right: 0.5rem;
      display: block;
      background-color: ${color.black};
      border-radius: 50%;
    }

    ::after {
      content: '';
      flex: 1 1 auto;
      margin-left: 0.5rem;
      height: 1px;
      display: block;
      background-color: ${color.black};
    }
  }

  > p {
    font-size: 0.8rem;
    margin: 0;
    line-height: 2;
  }
`;

const Address = styled.div`
  margin: 1rem 0 0;
  font-size: 0.75rem;
`;

const PhoneNumber = styled.div`
  margin: 1rem 0 0;
  font-size: 0.75rem;
`;

const GoogleMapLink = styled.a`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  text-decoration: none;
  color: ${color.black};

  svg {
    fill: ${color.red};
  }
`;

const ReservationLink = styled.a`
  margin: 2rem auto 0;
  max-width: 20rem;
  display: block;
  width: 100%;
  text-decoration: none;
  color: ${color.grey[900]};
`;

export default AccessClinic;
