import '@fontsource/noto-sans-jp';
import React from 'react';
import Layout from './src/components/Layout';
import { SSRProvider } from '@react-aria/ssr';

const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

const wrapRootElement = ({ element }) => {
  return <SSRProvider>{element}</SSRProvider>;
};

export { wrapRootElement, wrapPageElement };
