import React from 'react';
import styled from 'styled-components';
import { color, font } from '../../styles/variables';

type Props = {
  label: string;
  children?: React.ReactNode;
};

const OutlinedButton: React.VFC<Props> = ({ children, label }) => (
  <Container>
    <Content>
      {children && <IconContainer>{children}</IconContainer>}
      <LabelContainer>{label}</LabelContainer>
    </Content>
  </Container>
);

const Container = styled.div`
  width: 100%;
  border: 4px solid black;
  height: 3.5rem;
  font-family: ${font.sansSerif};
  transition: 0.2s;

  &:hover {
    background-color: ${color.grey[100]};
  }
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  text-align: center;
  margin: 0 auto;
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelContainer = styled.div`
  font-size: 0.9rem;
  letter-spacing: 0.15em;
  font-weight: 700;
  padding: 0 0.5rem;
  text-align: center;
  flex: 1;
`;

export default OutlinedButton;
