import React from 'react';
import styled from 'styled-components';
import PageHeading from '../common/PageHeading';
import { MenuTextSVG } from '../common/TextSVG';

const MenuHeading = () => {
  return (
    <>
      <PageHeading>
        <MenuTextSVG />
      </PageHeading>
      <Subhead>美容皮膚科専門医による丁寧なカウンセリングで、一人ひとりに合わせた治療をご提案いたします。</Subhead>
    </>
  );
};

const Subhead = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 1rem 0 0;
  padding: 0 1rem;
`;

export default MenuHeading;
