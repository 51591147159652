import React from 'react';
import styled from 'styled-components';
import FAQItem from './FAQItem';
import { mediaQuery } from '../../../styles/variables';
import { MenuTreatmentProps } from './TreatmentList';

type Props = Pick<MenuTreatmentProps, 'contentId' | 'faq'>;

const FAQ: React.VFC<Props> = ({ contentId, faq }) => {
  return (
    <Container>
      <Heading>FAQ</Heading>
      {faq.map((faq, i) => (
        <FAQItem question={faq.question} answer={faq.answer} annotation={faq.annotation} key={`Faq-${contentId}-${i}`} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin: 2rem auto 0;
  width: 100%;
  max-width: 60rem;
`;

const Heading = styled.p`
  font-size: 1.6rem;
  letter-spacing: 0.4em;
  text-align: center;
  margin: 0 0 1rem;

  @media screen and ${mediaQuery.tablet} {
    font-size: 1.2rem;
  }
`;

export default FAQ;
