import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { NextIcon, PrevIcon } from '../common/Icon';
import CarouselElement from './CarouselElement';
import { useMediaQuery } from 'react-responsive';
import { mediaQuery } from '../../styles/variables';

type Props = {
  data: any; // 型を付けたい場合は、gatsby-plugin-graphql-codegenで対応できる
};

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 300 : -300,
    opacity: 0,
  }),
};

// 一度にスライドする要素数
const contentLength = 7;

// スワイプの移動距離と速度から力の値を定義
const swipePower = (offset: number, velocity: number) => Math.abs(offset) * velocity;

// swipePowerの閾値
const swipeConfidenceThreshold = 10000;

const TopCarouselMenu: React.VFC<Props> = ({ data }) => {
  const isSmall = useMediaQuery({
    query: mediaQuery.laptop,
  });

  // 表示するスライドの数
  const [slideLength, setSlideLength] = useState(0);

  // index -> 現在表示しているスライドの先頭要素のindex
  // direction -> スライドした方向: [-1 -> 左] [1 -> 右]
  const [[index, direction], setIndex] = useState([0, 0]);

  // viewportによって表示するスライドの数を変える
  useEffect(() => {
    if (isSmall) {
      setSlideLength(1);
    } else {
      setSlideLength(3);
    }
  }, [isSmall]);

  // スライドによってindexを更新
  const paginate = (newDirection: number) => {
    setIndex(([index]) => {
      return [index + newDirection * slideLength, newDirection];
    });
  };

  // 表示するスライド要素のindex配列を返す
  const visibleItemIndex = (currentIndex: number) => {
    const itemIndexArray = [];

    // 現在のindexに対して、表示するべき要素のindex配列を返す
    let newIndex;
    if (currentIndex < 0) {
      newIndex = contentLength - (Math.abs(currentIndex) % contentLength);
    } else {
      newIndex = currentIndex;
    }
    for (let i = 0; i < slideLength; i++) {
      itemIndexArray.push((newIndex + i) % contentLength);
    }
    return itemIndexArray;
  };

  return (
    <>
      <Container>
        <AnimatePresence
          // マウント時の初回アニメーションを無効
          initial={false}
          // exit propが動的な場合、このcustomを設定することで最新のpropが渡る
          custom={direction}
        >
          <Contents
            key={index}
            // 動的なvariantsのためのprop
            custom={direction}
            // コンポーネントの状態を事前に定義
            variants={variants}
            // コンポーネントのマウント時のアニメーション
            initial="enter"
            // アニメーションが終わった時の静止状態
            animate="center"
            // コンポーネントのアンマウント時のアニメーション
            exit="exit"
            transition={{
              // stiffness -> 変動のしにくさ
              // damping -> 振動の吸収度合
              x: { type: 'spring', stiffness: 300, damping: 40 },
              opacity: { duration: 0.2 },
            }}
            // ドラッグ方向
            drag="x"
            // ドラッグ後に要素が戻る位置
            dragConstraints={{ left: 0, right: 0 }}
            // ドラッグの移動距離に対しての要素の移動比率
            dragElastic={0.4}
            onDragEnd={(_, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            <ul>
              {visibleItemIndex(index).map((itemIndex) => (
                <CarouselElement key={`CarouselElement-${itemIndex}`} index={itemIndex} data={data} />
              ))}
            </ul>
          </Contents>
        </AnimatePresence>
        <Prev onClick={() => paginate(-1)}>
          <PrevIcon size={32} />
        </Prev>
        <Next onClick={() => paginate(1)}>
          <NextIcon size={32} />
        </Next>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 60rem;
  position: relative;
  height: 13rem;
  margin: 0 auto;

  @media screen and ${mediaQuery.laptop} {
    width: 24rem;
  }

  @media screen and ${mediaQuery.mobile} {
    width: 100%;
  }
`;

const Contents = styled(motion.div)`
  width: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;

  > ul {
    display: flex;
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;
    justify-content: space-between;
  }

  @media screen and ${mediaQuery.laptop} {
    > ul {
      justify-content: center;
    }
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
  margin: auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(37, 30, 28, 0.1);
  border-radius: 50%;
  z-index: 20;
`;

const Prev = styled(Arrow)`
  left: -4rem;
  @media screen and ${mediaQuery.mobile} {
    left: -0.8rem;
  }
`;

const Next = styled(Arrow)`
  right: -4rem;

  @media screen and ${mediaQuery.mobile} {
    right: -0.8rem;
  }
`;

export default TopCarouselMenu;
