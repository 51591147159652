import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import ViewMoreButton from '../common/ViewMoreButton';
import { useMediaQuery } from 'react-responsive';
import { mediaQuery } from '../../styles/variables';
import FadeIn from '../common/FadeIn';
import { Link } from 'gatsby';
import { DoctorsTextSVG } from '../common/TextSVG';

const TopDoctorsSection = () => {
  const isMobile = useMediaQuery({
    query: mediaQuery.mobile,
  });
  return (
    <Container>
      <SectionText>
        <TextContainer>
          <Heading>
            <DoctorsTextSVG />
          </Heading>
          <SubHeading>CLINIC FORの美容医療を支えるドクターたち。</SubHeading>
          {isMobile && (
            <ImageContainerSP>
              <StaticImage src="../../images/top/top_doctor_sp.jpg" alt="" />
            </ImageContainerSP>
          )}
          <ButtonLayout>
            <Link to={'/about'}>
              <ViewMoreButton />
            </Link>
          </ButtonLayout>
        </TextContainer>
      </SectionText>
      {!isMobile && (
        <ImageContainerPC>
          <FadeIn direction={'toTop'}>
            <StaticImage src="../../images/top/top_doctor_pc.jpg" alt="" width={640} height={280} />
          </FadeIn>
        </ImageContainerPC>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 54rem;
  margin: 8rem auto 0;
  position: relative;
  display: flex;

  @media screen and ${mediaQuery.mobile} {
    margin: 4rem auto 0;
  }
`;

const Heading = styled.h2`
  width: 24rem;
  margin: 0 auto;
  padding: 0 2rem;

  > svg {
    width: 100%;
  }

  @media screen and ${mediaQuery.mobile} {
    width: 16rem;
  }
`;

const SubHeading = styled.p`
  margin-top: 1rem;
  font-size: 0.9rem;

  @media screen and ${mediaQuery.mobile} {
    margin-top: 0.4rem;
    font-size: 0.75rem;
  }
`;

const SectionText = styled.div`
  width: 32rem;
  text-align: center;
  position: relative;
  z-index: 2;

  @media screen and ${mediaQuery.mobile} {
    width: 100%;
    padding: 2rem 0 0;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 3rem;
  left: 0;

  @media screen and ${mediaQuery.mobile} {
    position: relative;
    top: auto;
    left: auto;
  }
`;

const ImageContainerPC = styled.div`
  flex: 0 0 36rem;
`;

const ImageContainerSP = styled.div`
  width: 100%;
  margin: 2rem 0 0;
`;

const ButtonLayout = styled.div`
  margin: 2rem 0 0;
`;

export default TopDoctorsSection;
