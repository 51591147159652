import React from 'react';
import styled from 'styled-components';
import AccessClinic from '../components/access/AccessClinic';
import { mediaQuery } from '../styles/variables';
import { Meta } from '../components/common/Meta';
import FloatingButton from '../components/common/FloatingButton';
import PageHeading from '../components/common/PageHeading';
import { AccessTextSVG } from '../components/common/TextSVG';

const AccessPage = () => {
  return (
    <Container>
      <Meta title={'アクセス'} />
      <PageHeading>
        <AccessTextSVG />
      </PageHeading>
      <Description>駅近くの立地で通いやすく、平日21:00まで開院。オンライン予約で、忙しい方にも効率的です。</Description>
      <AccessClinic />
      <FloatingButton visible={true} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 8rem 0;
`;

const Description = styled.p`
  font-size: 1rem;
  margin-top: 1rem;
  line-height: 1.7;
  text-align: center;
  padding: 0 1rem;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.8rem;
  }
`;

export default AccessPage;
