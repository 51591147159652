import React from 'react';
import styled from 'styled-components';

import { mediaQuery } from '../../styles/variables';

const ReservationLinksStyle = styled.div`
  font-family: Helvetica Neue;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 32px 25px;
  @media screen and ${mediaQuery.laptop} {
    width: 100%;
    padding: 20px;
    flex-wrap: wrap;
  }
  .wrapper {
    width: 960px;
    background: #f2e3e2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 32px 25px;
    @media screen and ${mediaQuery.laptop} {
      width: 100%;
      padding: 16px 16px;
      flex-wrap: wrap;
    }
    .texts {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 2px;
      }
      .description {
        font-size: 12px;
        font-weight: 300;
      }
    }
    .links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: space-between;
      width: 100%;
      @media screen and ${mediaQuery.tablet} {
        display: grid;
        grid-template-columns: 50% 50%;
        row-gap: 12px;
      }
      .link-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .link {
        height: 45px;
        width: 100%;
        margin: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 100px;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        color: black;
        @media screen and ${mediaQuery.laptop} {
          width: 100%;
        }
      }
      .link:hover {
        background: #f4f4f4;
      }
    }
  }
`;

export const ReservationLinks = () => (
  <ReservationLinksStyle>
    <div className="wrapper">
      <div className="texts">
        <span className="title" id="reservation-title">
          RESERVATION
        </span>
        <span className="description">クリニックのご予約</span>
      </div>
      <div className="links">
        <div className="link-wrapper">
          <a
            className="link"
            id="reservation-link"
            href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_3&department=cosmetic_dermatology"
          >
            新橋
          </a>
        </div>
        <div className="link-wrapper">
          <a
            className="link"
            id="reservation-link"
            href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_1&department=cosmetic_dermatology"
          >
            田町
          </a>
        </div>
        <div className="link-wrapper">
          <a
            className="link"
            id="reservation-link"
            href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_138&department=cosmetic_dermatology"
          >
            池袋
          </a>
        </div>
        <div className="link-wrapper">
          <a
            className="link"
            id="reservation-link"
            href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_8&department=cosmetic_dermatology"
          >
            四谷
          </a>
        </div>
        <div className="link-wrapper">
          <a
            className="link"
            id="reservation-link"
            href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_72&department=cosmetic_dermatology"
          >
            心斎橋
          </a>
        </div>
      </div>
    </div>
  </ReservationLinksStyle>
);
