export const troubleContentList = [
  {
    name: 'diet',
    text: '肥満症治療',
    link: '/menu/treat#treatment-21',
  },
  {
    name: 'co2_laser',
    text: 'CO2レーザー',
    link: '/menu/treat#treatment-4',
  },
  {
    name: 'beautiful_skin',
    text: 'Beautiful skin',
    link: '/menu/treat#treatment-27',
  },
  {
    name: 'ed',
    text: 'ED',
    externalLink: 'https://www.clinicfor.life/disease-tx/telemedicine-ed/',
  },
  {
    name: 'plus_restore',
    text: 'プラスリストア',
    link: '/menu/treat#treatment-27',
  },
  {
    name: 'hair_growth',
    text: '薄毛治療',
    link: '/menu/treat#treatment-22',
  },
  {
    name: 'melting',
    text: '脂肪燃焼注射',
    link: '/menu/treat#treatment-21',
  },
  {
    name: 'fatigue_recovery',
    text: '疲労回復ドリップ',
    link: '/menu/treat#treatment-18',
  },
  {
    name: 'hyaluronic_acid',
    text: 'ヒアルロン酸',
    link: '/menu/treat#treatment-14',
  },
  {
    name: 'sono_queen',
    text: 'ソノクイーン',
    link: '/menu/treat#treatment-7',
  },
  {
    name: 'botox',
    text: 'ボトックス',
    link: '/menu/treat#treatment-13',
  },
  {
    name: 'q_switch_laser',
    text: 'Qスイッチルビーレザー',
    link: '/menu/treat#treatment-5',
  },
  {
    name: 'ultra_cell',
    text: 'ウルトラセルQプラス',
    link: '/menu/treat#treatment-8',
  },
  {
    name: 'thread_lift',
    text: '糸リフト',
    link: '/menu/treat#treatment-10',
  },
  {
    name: 'rejuran',
    text: 'リジュラン',
    link: '/menu/treat#treatment-15',
  },
  {
    name: 'co2_fractional_laser',
    text: 'CO2フラクショナルレーザー',
    link: '/menu/treat#treatment-6',
  },
  {
    name: 'prp',
    text: 'PRP',
    link: '/menu/treat#treatment-16',
  },
  {
    name: 'massage_peel',
    text: 'マッサージピール',
    link: '/menu/treat#treatment-1',
  },
  {
    name: 'uber_peel',
    text: 'ウーバーピール',
    link: '/menu/treat#treatment-2',
  },
  {
    name: 'ion',
    text: 'イオン導入',
    link: '/menu/treat#treatment-3',
  },
  {
    name: 'photo_facial',
    text: 'フォトフェイシャル',
    link: '/menu/treat#treatment-9',
  },
  {
    name: 'dermapen',
    text: 'ダーマペン',
    link: '/menu/treat#treatment-11',
  },
  {
    name: 'water_light_injection',
    text: '水光注射',
    link: '/menu/treat#treatment-12',
  },
  {
    name: 'cosme_injection',
    text: '美容注射・美容点滴',
    link: '/menu/treat#treatment-17',
  },
  {
    name: 'vitamin_c',
    text: '高濃度ビタミンC点滴',
    link: '/menu/treat#treatment-20',
  },
  {
    name: 'tretinoin',
    text: 'トレチノイン＆ハイドロキノン',
    link: '/menu/treat#treatment-23',
  },
  {
    name: 'whitening',
    text: '美白内服',
    link: '/menu/treat#treatment-24',
  },
  {
    name: 'zo_skin',
    text: 'ゼオスキンヘルス',
    link: '/menu/treat#treatment-27',
  },
];
