import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import styled from 'styled-components';

import { color, mediaQuery } from '../../styles/variables';

const TopTreatmentList = () => {
  return (
    <Container>
      <li>
        <Heading>マッサージピール</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-1'}>マッサージピール（PRX-T33）</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-2'}>ウーバーピール</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>イオン導入</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-3'}>イオン導入</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>レーザー治療</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-4'}>CO2レーザー</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-5'}>Qスイッチルビーレーザー</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-6'}>CO2フラクショナルレーザー</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>HIFU</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-7'}>ソノクイーン</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-8'}>ウルトラセルQ+</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>フォトフェイシャル</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-9'}>フォトフェイシャル（M-22）</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>ダーマペン</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>ダーマペン4</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>ウーバーピール</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>ベルベットスキン</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>ヴァンパイアフェイシャル</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>成長因子（ベネブ社）</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>注入治療</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-12'}>水光注射</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-13'}>ボトックス</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-14'}>ヒアルロン酸</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-16'}>PRP</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>注射・点滴</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-17'}>美容注射・美容点滴</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-18'}>疲労回復ドリップ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-19'}>プラセンタ注射</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-20'}>高濃度ビタミンC点滴</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>肥満症治療</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-21'}>注射</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-21'}>内服</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>薄毛治療</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-22'}>AGA</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-22'}>FAGA</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>ホームケア</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-23'}>トレチノイン＆ハイドロキノン</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-24'}>美白内服セット</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-25'}>その他美容処方薬</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>低用量ピル</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-26'}>低用量ピル</AnchorLink>
          </li>
        </TreatmentList>
      </li>
      <li>
        <Heading>医療機関専売コスメ</Heading>
        <TreatmentList>
          <li>
            <AnchorLink to={'/menu/treat#treatment-27'}>エンビロン</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-27'}>ゼオスキンヘルス</AnchorLink>
          </li>
        </TreatmentList>
      </li>
    </Container>
  );
};

const Container = styled.ul`
  margin: 2rem auto 0;
  padding: 0 1rem;
  width: 100%;
  max-width: 60rem;
  list-style: none;
  column-count: 3;
  column-gap: 2rem;

  > li {
    break-inside: avoid;
    margin-bottom: 2.2rem;
  }

  @media screen and ${mediaQuery.laptop} {
    column-count: 2;
    column-gap: 1rem;
  }
`;

const TreatmentList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    > a {
      padding: 1rem;
      background-color: ${color.grey[100]};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: ${color.black};
      text-decoration: none;

      &:hover {
        background-color: ${color.pink};
      }
    }
  }

  > li + li {
    margin-top: 0.5rem;
  }

  @media screen and ${mediaQuery.laptop} {
    font-size: 0.8rem;
  }
`;

const Heading = styled.p`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-color: ${color.pink};
  }

  @media screen and ${mediaQuery.laptop} {
    font-size: 0.9rem;
  }
`;

export default TopTreatmentList;
