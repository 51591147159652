import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { color, font, mediaQuery } from '../../styles/variables';
import { ReservationIcon } from './Icon';

type Props = {
  visible: boolean;
};

const FloatingButton: React.VFC<Props> = ({ visible }) => {
  return (
    <Container>
      <AnimatePresence>
        {visible && (
          <Content
            initial={{ y: 200 }}
            animate={{ y: 0 }}
            exit={{ y: 200 }}
            transition={{
              duration: 0.3,
            }}
          >
            <Heading>
              <IconContainer>
                <ReservationIcon size={24} />
              </IconContainer>
              <p>Webで今すぐ予約</p>
            </Heading>
            <LinkContainer>
              <a href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_3&department=cosmetic_dermatology">新橋</a>
              <a href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_1&department=cosmetic_dermatology">田町</a>
              <a href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_138&department=cosmetic_dermatology">池袋</a>
              <a href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_8&department=cosmetic_dermatology">四谷</a>
              <a href="https://miniapp.clinicfor.life/reservation/reservation?clinicId=Clinic_72&department=cosmetic_dermatology">心斎橋</a>
            </LinkContainer>
          </Content>
        )}
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 0;
  z-index: 10;
  padding: 0 2rem;
  font-family: ${font.sansSerif};

  @media screen and ${mediaQuery.mobile} {
    width: 100%;
    padding: 0;
    bottom: 0;
  }
`;

const Content = styled(motion.div)`
  display: block;
  width: 26rem;
  background-color: rgba(229, 200, 197, 0.8);
  margin: 0 0 0 auto;
  padding: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);

  @media screen and ${mediaQuery.mobile} {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;

  > p {
    font-size: 0.75rem;
    margin-left: 0.5rem;
  }
`;

const IconContainer = styled.div`
  flex: 0 0 24px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
  width: 100%;

  @media screen and ${mediaQuery.mobile} {
    width: 10rem;
    margin-top: 0;
    margin-left: 0.5rem;
    flex: 0 0 15rem;
  }

  > a {
    background-color: ${color.white};
    padding: 0.6rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18%;
    font-size: 0.7rem;
    font-weight: 700;
    letter-spacing: 0.2em;
    color: ${color.grey[900]};
    text-decoration: none;

    &:hover {
      background-color: ${color.grey[100]};
    }
  }
`;

export default FloatingButton;
