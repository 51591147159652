import React from 'react';
import styled from 'styled-components';
import { color, mediaQuery } from '../../styles/variables';
import { GatsbyImage } from 'gatsby-plugin-image';

type OptionProps = {
  name: string;
  price: string;
};

type Props = {
  index: number;
  data: any; // 型を付けたい場合は、gatsby-plugin-graphql-codegenで対応できる
};

const CarouselElement: React.VFC<Props> = ({ index, data }) => {
  const image = data.image.edges[index].node.childImageSharp.gatsbyImageData;
  const content = data.content.edges[0].node.childrenTopContentYaml[0].content[index];

  return (
    <Container key={`CarouselItem-${index}`}>
      <LinkContainer href={content.link}>
        <Body>
          <Headline>{content.head}</Headline>
          <Description>{content.description}</Description>
          <Decoration role="presentation">
            <GatsbyImage alt={content.head} image={image} />
          </Decoration>
        </Body>
        <Footer>
          <Label>
            期間
            <br />
            限定
          </Label>
          <OptionContainer>
            <div>
              {content.option.map((option: OptionProps, optionIndex: number) => (
                <Option key={`CarouselItem-${index}-${optionIndex}`}>
                  {option.name && <Name>{option.name}</Name>}
                  <Price>{option.price}</Price>
                </Option>
              ))}
            </div>
          </OptionContainer>
        </Footer>
      </LinkContainer>
    </Container>
  );
};

const Container = styled.li`
  width: 32%;

  @media screen and ${mediaQuery.laptop} {
    width: 100%;
  }

  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
  }
`;

const LinkContainer = styled.a`
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.2s;
  height: 100%;
  text-decoration: none;
  color: ${color.black};

  &:hover {
    transform: scale(1.03);
  }
`;

const Body = styled.div`
  padding: 1rem 2rem;
  position: relative;

  @media screen and ${mediaQuery.mobile} {
    padding: 1rem 2.5rem;
  }
`;

const Decoration = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 3rem;
`;

const Headline = styled.div`
  font-size: 1.4rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed ${color.black};
  position: relative;
  z-index: 2;

  @media screen and ${mediaQuery.mobile} {
    font-size: 1.2rem;
  }
`;

const Description = styled.div`
  font-size: 0.72rem;
  padding: 0.5rem 0 0;
  line-height: 1.5;
  position: relative;
  z-index: 2;
`;

const Footer = styled.div`
  display: flex;
  background-color: ${color.pink};
  align-items: center;
  padding: 0 0.5rem;
`;

const Label = styled.div`
  background-color: ${color.black};
  color: ${color.white};
  font-size: 0.75rem;
  flex: 0 0 2.5rem;
  line-height: 1.4;
  padding: 0.4rem 0;
  text-align: center;
`;

const OptionContainer = styled.div`
  flex: 1 1 auto;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.p`
  font-size: 0.75rem;
  margin-right: 0.5rem;
  font-weight: 700;
  color: ${color.black};
`;

const Price = styled.p`
  font-size: 0.75rem;
`;

export default CarouselElement;
