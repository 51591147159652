import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Meta } from '../components/common/Meta';
import { color, mediaQuery } from '../styles/variables';

const ThanksPage = () => {
  return (
    <Container>
      <Meta title={`お問い合わせありがとうございました`} />
      <Message>お問い合わせありがとうございました</Message>
      <p>この度は、ClinicForにお問い合わせ頂きまして、誠にありがとうございます。</p>
      <p>担当者にて早急に内容を確認させて頂きまして、1営業日以内に改めてご返信・ご連絡させていただきます。</p>
      <p>
        お問合せ先: <Contact>beauty@clinicfor.life</Contact>
      </p>
      <LinkContainer>
        <Link to={'/'}>トップに戻る</Link>
      </LinkContainer>
    </Container>
  );
};
const Container = styled.div`
  padding: 0 1rem;
  margin: 3rem auto 0;
  width: 100%;

  > p {
    font-size: 0.9rem;
    line-height: 3;
    text-align: center;
  }

  @media screen and ${mediaQuery.mobile} {
    margin: 2rem 0;

    > p {
      line-height: 2;
      text-align: left;
    }
  }
`;

const Message = styled.div`
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 3rem;
  letter-spacing: 0.15em;
  @media screen and ${mediaQuery.mobile} {
    font-size: 1.4rem;
  }
`;

const LinkContainer = styled.div`
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  > a {
    color: ${color.grey[900]};
    border: 1px solid ${color.grey[900]};
    border-radius: 4px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      background-color: ${color.grey[100]};
    }
  }
`;

const Contact = styled.span`
  text-decoration: underline;
`;

export default ThanksPage;
