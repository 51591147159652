import React from 'react';
import styled from 'styled-components';
import { Meta } from '../components/common/Meta';

const NotFoundPage = () => {
  return (
    <Container>
      <Meta title={'NOT FOUND 404'} />
      お探しのページは見つかりませんでした。
    </Container>
  );
};

const Container = styled.p`
  margin: 8rem auto;
  text-align: center;
`;

export default NotFoundPage;
