import React from 'react';
import styled from 'styled-components';
import { color, font, mediaQuery } from '../styles/variables';
import { Meta } from '../components/common/Meta';

const PrivacyPage = () => {
  return (
    <Container>
      <Meta title={'プライバシーポリシー'} />
      <Message>個人情報保護方針</Message>
      <Section>
        <Heading>1. 個人情報の利用目的について</Heading>
        <Content>
          当社は、取得した個人情報を下記の目的で利用いたします
          <ul>
            <li>1.当社サービスの提供のため</li>
            <li>2.お問い合わせ等の対応のため</li>
            <li>3.メール配信、アンケート、キャンペーンの実施のため</li>
            <li>4.マーケティングデータの調査、統計、分析のため</li>
            <li>5.当社および当社と提携する企業からの情報提供のため（広告又は宣伝を含む。なお、情報提供は当社が行うものとします。）</li>
            <li>
              6.当社は、サービス向上を目的として、患者様より収集した情報を基に匿名加工情報（個人情報保護法第2条第9項に定めるものを意味し、同法第2条第10項に定める匿名加工情報データベース等を構成するものに限ります。以下同じ。）を作成し、これを第三者に提供することがあります。匿名加工情報に含まれる情報の項目及び提供方法は次の通りです。なお、提供先となる第三者に対して、提供する情報が匿名加工情報であることを明示します。
              <ul>
                <li>
                  <p>a.個人に関する情報の項目：</p>
                  <p>生年月(日を除く)、性別、予約取得日時、受診日時、来院目的、事前問診結果</p>
                </li>
                <li>
                  <p>b.提供の方法：</p>
                  <p>パスワードにより保護された電子ファイルを外部記憶媒体で手交提供する際にあらかじめ公表する予定</p>
                </li>
              </ul>
            </li>
          </ul>
        </Content>
      </Section>

      <Section>
        <Heading>2. 個人情報の第三者への開示について</Heading>
        <Content>
          当社は、次の場合を除き、個人情報を第三者へ開示または提供いたしません。
          <ul>
            <li>1.ご本人の同意がある場合</li>
            <li>2.ご本人を識別できない状態（統計資料等）で開示する場合</li>
            <li>3.個人情報保護法その他の法令に基づき提供の必要がある場合</li>
            <li>4.提供を受ける者が個人情報保護法に基づく第三者に該当しない場合</li>
            <li>5.当社サービスのご利用等に係るお支払いに際し、クレジット決済のため金融機関等に個人情報を開示する必要がある場合</li>
          </ul>
        </Content>
      </Section>

      <Section>
        <Heading>3. Webサイトにおける個人情報等の取扱いについて</Heading>
        <Content>
          <p>
            当院は、当院が運営するウェブサイト（以下、「当院サイト」といいます）において、ログファイルの収集、クッキーの送信、Webビーコンの設置等により、サイト利用者様の行動履歴を収集しています。これらの情報は、当院サイトの利用傾向の分析や問題発生時の原因究明に利用されます。
          </p>
          <p>
            ※クッキーは、ブラウザの設定によって受け入れを拒否することができますが、その場合には当院サイトの一部が利用できなくなる可能性がありますのでご留意ください。
          </p>
        </Content>
      </Section>

      <Section>
        <Heading> 4. 個人番号および特定個人情報の取扱いについて</Heading>
        <Content>
          <p>
            行政手続における特定の個人を識別するための番号の利用等に関する法律（番号法）にて定められている個人番号および特定個人情報は、同法で限定的に明記された目的以外のために取得・利用しません。番号法で限定的に明記された場合を除き、個人番号および特定個人情報を第三者に提供しません。また、共同利用も行いません。
          </p>
          <p>※なお、お客様から個人番号及び特定個人情報を取得・利用・保管することはありません。</p>
        </Content>
      </Section>

      <Section>
        <Heading>5. 個人情報の開示・訂正等について</Heading>
        <Content>
          当院は、開示対象個人情報について、開示・訂正・追加または削除・利用の停止・消去および第三者への提供の停止（以下、「開示等」といいます。）などに対応させていただきます。{' '}
          <ul>
            <li>
              <p>1.ご希望に応じられない場合はその理由をご説明いたします。</p>
              <p>
                また、当該お申し出によって取得した個人情報は、お申し出に関する連絡・事務手続きに必要な範囲で利用いたします。 <br />
              </p>
            </li>
            <li>
              <p>2.問い合わせ窓口へメールをご送信いただければ、当院担当者が担当致します。</p>
              <p>具体的な個人情報開示の手続方法・手数料等についてご説明をさせていただきます。</p>
            </li>
          </ul>
        </Content>
      </Section>

      <Section>
        <Heading>6. お問い合わせ先</Heading>
        <Content>
          <p>当院の個人情報の取扱いに関するお問い合わせは、下記までご連絡ください。</p>
          <p>
            <b style={{ fontWeight: 'bold' }}>【クリニックフォアグループ 個人情報の取扱いに関するお問い合わせ窓口】</b>
          </p>
          <p>〒108-0023 東京都港区芝浦3-1-32 なぎさテラス5F</p>
          <p>E-mailでのお問い合わせ：online-support@clinicfor.life（平日10〜18時、年末年始等は休み）</p>
        </Content>
      </Section>

      <Section>
        <Heading>7. プライバシーポリシーの変更について</Heading>
        <Content>
          当院は、法令・各種ガイドラインの制定や変更、当院の判断等により予告なく変更される場合があります。変更後のプライバシーポリシーは、本ウェブサイト上に表示された時点より効力を生じます。
        </Content>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 60rem;
  padding: 5rem 1rem;
  margin: 0 auto;
  font-family: ${font.sansSerif};

  @media screen and ${mediaQuery.mobile} {
    padding: 3rem 1rem;
  }
`;

const Section = styled.section`
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

const Heading = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  white-space: pre-line;
  font-size: 0.9rem;
  line-height: 1.8;
  letter-spacing: 0.05em;
  color: ${color.grey[900]};

  > ul {
    list-style: none;
    padding: 0 0 0 1rem;
    margin: 1rem 0;

    > li {
      > ul {
        list-style: none;
        margin: 1rem 0;
        padding: 0 0 0 1rem;

        > li + li {
          margin-top: 0.5rem;
        }
      }
    }

    > li + li {
      margin-top: 0.5rem;
    }
  }
`;

const Message = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.2em;
  color: ${color.grey[900]};
  margin-bottom: 2rem;

  @media screen and ${mediaQuery.mobile} {
    font-size: 1.2rem;
    text-align: left;
  }
`;

export default PrivacyPage;
