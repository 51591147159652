import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../styles/variables';

type Props = {
  children: React.ReactNode;
};

const PageHeading: React.VFC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: 2.4rem;
  }

  @media screen and ${mediaQuery.mobile} {
    > svg {
      height: 2rem;
    }
  }
`;

export default PageHeading;
