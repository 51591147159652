import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  NavigationAboutSVG,
  NavigationAccessSVG,
  NavigationContactSVG,
  NavigationMenuSVG,
  NavigationPriceSVG,
  NavigationVoiceSVG,
} from '../TextSVG';
import { color, font } from '../../../styles/variables';
import { ChevronRightIcon } from '../Icon';
import { useHover } from '@react-aria/interactions';

const NavigationPC = () => {
  const { hoverProps, isHovered } = useHover({});
  return (
    <Container>
      <ul>
        <li {...hoverProps}>
          <MenuContainer>
            <NavigationMenuSVG />
          </MenuContainer>
          {isHovered && (
            <FloatingMenu>
              <ul>
                <li>
                  <Link to={'/menu/treat'}>
                    施術別で探す
                    <ChevronRightIcon size={24} color={'#fff'} />
                  </Link>
                </li>
                <li>
                  <Link to={'/menu/trouble'}>
                    お悩みで探す
                    <ChevronRightIcon size={24} color={'#fff'} />
                  </Link>
                </li>
              </ul>
            </FloatingMenu>
          )}
        </li>
        <li>
          <Link to={'/price'}>
            <NavigationPriceSVG />
          </Link>
        </li>
        <li>
          <Link to={'/access'}>
            <NavigationAccessSVG />
          </Link>
        </li>
        <li>
          <Link to={'/about'}>
            <NavigationAboutSVG />
          </Link>
        </li>
        {/*
+      ・ステマ規制法を考慮して、Voiceは一旦非表示にする。
+      ・背景: https://lincwell.slack.com/archives/C018EB1G56D/p1696214174655059
        <li>
          <Link to={'/voice'}>
            <NavigationVoiceSVG />
          </Link>
        </li>
        */}
        <li>
          <Link to={'/contact'}>
            <NavigationContactSVG />
          </Link>
        </li>
      </ul>
    </Container>
  );
};

const Container = styled.nav`
  > ul {
    display: flex;
    list-style: none;
    font-size: 0.9rem;
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    font-weight: 700;

    > li {
      > a {
        padding: 1rem 1.5rem;
        display: block;
        > svg {
          height: 0.7rem;
        }
      }
    }
  }
`;

const MenuContainer = styled.div`
  padding: 1rem 1.5rem;
  > svg {
    height: 0.7rem;
  }
`;

const FloatingMenu = styled.div`
  position: relative;

  > ul {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    list-style: none;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 0.9rem;
    width: 11rem;
    padding: 1rem;
    line-height: 2;
    letter-spacing: 0.2em;
    font-family: ${font.sansSerif};

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: -8px;
      left: 24px;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
      border-width: 0 8px 8px 8px;
    }

    > li {
      > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: ${color.white};
      }
    }
  }
`;

export default NavigationPC;
