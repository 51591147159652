import React from 'react';
import styled from 'styled-components';
import TroubleList from '../../components/menu/trouble/TroubleList';
import TroubleIndex from '../../components/menu/trouble/TroubleIndex';
import { Meta } from '../../components/common/Meta';
import MenuHeading from '../../components/menu/MenuHeading';
import HeadingFullWidth from '../../components/menu/HeadingFullWidth';
import FloatingButton from '../../components/common/FloatingButton';

const ProblemPage = () => {
  return (
    <Container>
      <Meta title={'悩み別メニュー'} />
      <MenuHeading />
      <HeadingFullWidth text={'お悩み・症状から探す'} />
      <TroubleIndex />
      <TroubleList />
      <FloatingButton visible={true} />
    </Container>
  );
};

const Container = styled.div`
  margin: 8rem 0;
`;

export default ProblemPage;
