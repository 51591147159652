import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title?: string;
};

const logoImg = 'https://priceless-nightingale-ab3f86.netlify.com/assets/logo_2x.png';

const description =
  'CLINIC FOR BEAUTY（クリニックフォアビューティー）は、美容皮膚科のクリニックとして対面での院内施術・オンライン診療での内服薬・外用薬の処方・ドクターズコスメ（医療機関専売コスメ）など、さまざまなサポートを提供。月々定額制で美容施術・注射・点滴などが受けられる美容医療サブスクリプションプランもご用意。';

export const Meta: React.VFC<Props> = ({ title }) => {
  const pageTitle = title ? `${title} | 【公式】クリニックフォアビューティー | 美容皮膚科クリニック` : '【公式】クリニックフォアビューティー | 美容皮膚科クリニック';

  return (
    <Helmet>
      <link rel="apple-touch-icon" sizes="180x180" href="/assets/img/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/assets/img/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/assets/img/favicon/favicon-16x16.png" />
      <link rel="shortcut icon" href="/assets/img/favicon/favicon.ico" />
      <link rel="canonical" href="https://beauty.clinicfor.life/" />
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://beauty.clinicfor.life/" />
      <meta property="og:image" content={logoImg} />
      <meta property="og:site_name" content="Clinic For Beauty" />
      <meta property="og:description" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={logoImg} />
    </Helmet>
  );
};
