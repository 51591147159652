exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-access-tsx": () => import("./../../../src/pages/access.tsx" /* webpackChunkName: "component---src-pages-access-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menu-treat-tsx": () => import("./../../../src/pages/menu/treat.tsx" /* webpackChunkName: "component---src-pages-menu-treat-tsx" */),
  "component---src-pages-menu-trouble-tsx": () => import("./../../../src/pages/menu/trouble.tsx" /* webpackChunkName: "component---src-pages-menu-trouble-tsx" */),
  "component---src-pages-price-tsx": () => import("./../../../src/pages/price.tsx" /* webpackChunkName: "component---src-pages-price-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-pages-under-age-tsx": () => import("./../../../src/pages/under_age.tsx" /* webpackChunkName: "component---src-pages-under-age-tsx" */)
}

