import React from 'react';
import styled from 'styled-components';
import { ChevronRightIcon } from '../../common/Icon';
import { color } from '../../../styles/variables';

type Props = {
  link: string;
};

const TreatmentLinkButton: React.VFC<Props> = ({ link }) => {
  return (
    <Container href={link}>
      詳細はこちら
      <IconContainer>
        <ChevronRightIcon size={32} />
      </IconContainer>
    </Container>
  );
};

const Container = styled.a`
  display: block;
  position: relative;
  width: 100%;
  max-width: 24rem;
  margin: 3rem auto 0;
  padding: 1rem;
  border: 1px solid ${color.grey[800]};
  background-color: ${color.grey[100]};
  text-decoration: none;
  color: ${color.black};
  text-align: center;

  &:hover {
    background-color: ${color.grey[200]};
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  margin: auto;
  width: 32px;
  height: 32px;
`;

export default TreatmentLinkButton;
