import React from 'react';
import styled from 'styled-components';
import { color, mediaQuery } from '../../styles/variables';

type Props = {
  text: string;
};

const HeadingFullWidth: React.VFC<Props> = ({ text }) => {
  return (
    <Container>
      <Heading>{text}</Heading>
    </Container>
  );
};

const Container = styled.div``;
const Heading = styled.h2`
  background-color: ${color.pink};
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 0.2em;
  font-weight: 400;
  margin: 6rem 0;

  @media screen and ${mediaQuery.tablet} {
    font-size: 1rem;
  }
`;

export default HeadingFullWidth;
