import React from 'react';
import styled from 'styled-components';
import { AccessIcon, AfterCareIcon, ChevronBottomIcon, ChevronRightIcon, PriceIcon } from '../common/Icon';
import { StaticImage } from 'gatsby-plugin-image';
import { color, mediaQuery } from '../../styles/variables';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'gatsby';

const TopConceptCard: React.VFC = () => {
  const isMobile = useMediaQuery({
    query: mediaQuery.mobile,
  });
  return (
    <Layout>
      <li>
        <HeadlineContainer>
          <HeadlineContent>
            <HeadlineIcon>
              <PriceIcon size={24} />
            </HeadlineIcon>
            <Headline>PRICE</Headline>
          </HeadlineContent>
        </HeadlineContainer>
        <StaticImage src="../../images/top/top_concept_1.jpg" alt="" />
        <CardBody>
          <CardDescription>
            自由診療も明確
            <br />
            必要な処置を、
            {isMobile && <br />}
            適正な負担で
          </CardDescription>
          <CardLink to="/price">
            価格表を
            {isMobile && <br />}
            みる
            {isMobile && (
              <CardLinkArrow>
                <ChevronBottomIcon size={24} />
              </CardLinkArrow>
            )}
            {!isMobile && (
              <CardLinkArrow>
                <ChevronRightIcon size={24} />
              </CardLinkArrow>
            )}
          </CardLink>
        </CardBody>
      </li>
      <li>
        <HeadlineContainer>
          <HeadlineContent>
            <HeadlineIcon>
              <AccessIcon size={24} />
            </HeadlineIcon>
            <Headline>ACCESS</Headline>
          </HeadlineContent>
        </HeadlineContainer>
        <StaticImage src="../../images/top/top_concept_2.jpg" alt="" />
        <CardBody>
          <CardDescription>
            駅近21:00まで
            <br />
            効率的な
            {isMobile && <br />}
            オンライン予約
          </CardDescription>
          <CardLink to="/access">
            クリニックを
            {isMobile && <br />}
            探す
            {isMobile && (
              <CardLinkArrow>
                <ChevronBottomIcon size={24} />
              </CardLinkArrow>
            )}
            {!isMobile && (
              <CardLinkArrow>
                <ChevronRightIcon size={24} />
              </CardLinkArrow>
            )}
          </CardLink>
        </CardBody>
      </li>
      <li>
        <HeadlineContainer>
          <HeadlineContent>
            <HeadlineIcon>
              <AfterCareIcon size={24} />
            </HeadlineIcon>
            <Headline>AFTER CARE</Headline>
          </HeadlineContent>
        </HeadlineContainer>
        <StaticImage src="../../images/top/top_concept_3.jpg" alt="" />
        <CardBody>
          <CardDescription>
            施術後もケア
            <br />
            カウンセリング
            {isMobile && <br />}
            サポート
          </CardDescription>
          <CardExternalLink href="https://www.clinicfor.life/telemedicine/skincare/">
            オンライン
            {isMobile && <br />}
            診療を予約
            {isMobile && (
              <CardLinkArrow>
                <ChevronBottomIcon size={24} />
              </CardLinkArrow>
            )}
            {!isMobile && (
              <CardLinkArrow>
                <ChevronRightIcon size={24} />
              </CardLinkArrow>
            )}
          </CardExternalLink>
        </CardBody>
      </li>
    </Layout>
  );
};

const Layout = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;

  > li {
    background-color: ${color.grey[200]};
    width: 31%;
  }

  @media screen and ${mediaQuery.mobile} {
    > li {
      width: auto;
      flex: 1;
    }

    > li + li {
      border-left: 2px solid ${color.white};
    }
  }
`;

const HeadlineContainer = styled.div`
  width: 100%;
  padding: 0.7rem 1rem;
`;

const HeadlineContent = styled.div`
  display: flex;
  align-items: center;

  @media screen and ${mediaQuery.mobile} {
    display: block;
  }
`;

const HeadlineIcon = styled.div`
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.white};
  margin: 0 auto;
`;

const Headline = styled.div`
  font-size: 1rem;
  text-align: center;
  flex: 1;

  @media screen and ${mediaQuery.mobile} {
    margin: 0;
    font-size: 0.8rem;
    text-align: center;
  }
`;

const CardBody = styled.div`
  padding: 1rem 1rem 1.5rem;
`;

const CardDescription = styled.p`
  margin: 0;
  line-height: 1.6;
  font-size: 0.8rem;
  text-align: center;

  @media screen and ${mediaQuery.mobile} {
    font-size: 0.75rem;
  }
`;

const CardLink = styled(Link)`
  width: 100%;
  font-size: 0.75rem;
  margin-top: 1rem;
  padding: 0.5rem;
  position: relative;
  display: block;
  text-decoration: none;
  text-align: center;
  color: ${color.grey[900]};
  background-color: ${color.white};
  transition: 0.2s;

  &:hover {
    background-color: ${color.grey[100]};
  }
`;

const CardExternalLink = styled.a`
  width: 100%;
  font-size: 0.75rem;
  margin-top: 1rem;
  padding: 0.5rem;
  position: relative;
  display: block;
  text-decoration: none;
  text-align: center;
  color: ${color.grey[900]};
  background-color: ${color.white};
  transition: 0.2s;

  &:hover {
    background-color: ${color.grey[100]};
  }
`;

const CardLinkArrow = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and ${mediaQuery.mobile} {
    margin: 0 auto;
    width: 20px;
    position: static;
  }
`;

export default TopConceptCard;
