import React, { useRef } from 'react';
import styled from 'styled-components';
import { color, mediaQuery } from '../../../styles/variables';
import { useMediaQuery } from 'react-responsive';
import { MenuIcon } from '../Icon';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useButton } from '@react-aria/button';
import NavigationPC from './NavigationPC';
import NavigationSP from './NavigationSP';
import NavigationLogo from './NavigationLogo';
import { Link } from 'gatsby';

const GlobalNavigation = () => {
  const isLaptop = useMediaQuery({
    query: mediaQuery.laptop,
  });

  const state = useOverlayTriggerState({});
  const openButtonRef = useRef(null);
  const closeButtonRef = useRef(null);

  const { buttonProps: openButtonProps } = useButton(
    {
      onPress: () => state.open(),
    },
    openButtonRef
  );

  const { buttonProps: closeButtonProps } = useButton(
    {
      onPress: () => {
        state.close();
      },
    },
    closeButtonRef
  );

  return (
    <Container>
      <Content>
        <Link to={'/'}>
          <NavigationLogo />
        </Link>
        {isLaptop && (
          <ToggleButton {...openButtonProps} ref={openButtonRef}>
            <MenuIcon size={24} />
          </ToggleButton>
        )}
        {!isLaptop && <NavigationPC />}
        {isLaptop && <NavigationSP state={state} closeButtonRef={closeButtonRef} closeButtonProps={closeButtonProps} />}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 0.3rem 0;
  background-color: ${color.white};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  align-items: center;
  justify-content: space-between;

  @media screen and ${mediaQuery.tablet} {
    padding: 0 1rem;
  }
`;

const ToggleButton = styled.button`
  margin-left: auto;
  border: none;
  background-color: ${color.white};
  transition: 0.2s;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${color.grey[200]};
  }
`;

export default GlobalNavigation;
