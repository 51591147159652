import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { wrap } from 'popmotion';

const variants = {
  enter: {
    zIndex: 0,
    opacity: 0,
  },
  center: {
    zIndex: 1,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    opacity: 0,
  },
};

// sliderの表示切替時間
const SLIDE_INTERVAL = 7000;

const Slider: React.VFC = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(sort: { fields: name, order: ASC }, filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "hero" } }) {
        edges {
          node {
            relativeDirectory
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const images = data.allFile.edges;

  const [[page, direction], setPage] = useState([0, 0]);

  const imageIndex = wrap(0, images.length, page);

  const paginate = useCallback(
    (newDirection: number) => {
      setPage([page + newDirection, newDirection]);
    },
    [page]
  );

  // 一定時間ごとにslideを切り替えるeffect
  useEffect(() => {
    const timer = setInterval(() => paginate(1), SLIDE_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [page, paginate]);

  return (
    <Container>
      <AnimatePresence initial={false} custom={direction}>
        <SlideImage
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            opacity: { duration: 0.8 },
          }}
        >
          <GatsbyImage alt={''} image={images[imageIndex].node.childImageSharp.gatsbyImageData} style={{ height: '100%' }} />
        </SlideImage>
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SlideImage = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export default Slider;
