import React, { useRef } from 'react';
import styled from 'styled-components';
import { useOverlay, usePreventScroll, OverlayProvider } from '@react-aria/overlays';
import { FocusScope } from '@react-aria/focus';
import { color } from '../../../styles/variables';

const Overlay = (props: any) => {
  const { children } = props;
  const ref = useRef(null);
  const { overlayProps, underlayProps } = useOverlay(props, ref);
  usePreventScroll();
  return (
    <OverlayProvider>
      <OverlayMenuContainer {...underlayProps}>
        <FocusScope contain restoreFocus>
          <OverlayContent {...overlayProps} ref={ref}>
            {children}
          </OverlayContent>
        </FocusScope>
      </OverlayMenuContainer>
    </OverlayProvider>
  );
};

const OverlayMenuContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const OverlayContent = styled.div`
  background-color: ${color.pink};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default Overlay;
