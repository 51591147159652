import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { mediaQuery } from '../../styles/variables';
import styled from 'styled-components';
import { AboutTextSVG, HeroCopy2LineSVG, HeroCopy3LineSVG } from '../common/TextSVG';

const AboutHero = () => {
  const isMobile = useMediaQuery({
    query: mediaQuery.mobile,
  });
  return (
    <>
      <Heading>
        <AboutTextSVG />
      </Heading>
      {isMobile && (
        <HeroSP>
          <StaticImage src="../../images/hero/hero_3.jpg" alt="" />
          <HeroCopy2LineSVG />
        </HeroSP>
      )}
      {!isMobile && (
        <HeroPC>
          <HeroText>
            <h2>かかりつけの、美容皮膚科ができること。</h2>
            <HeroCopy3LineSVG />
          </HeroText>
          <HeroImagePC>
            <StaticImage src="../../images/hero/hero_3.jpg" alt="" width={640} height={280} />
          </HeroImagePC>
        </HeroPC>
      )}
    </>
  );
};

const Heading = styled.h1`
  width: 16rem;
  margin: 0 auto;

  > svg {
    width: 100%;
  }

  @media screen and ${mediaQuery.mobile} {
    width: 12rem;
  }
`;

const HeroPC = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 54rem;
  margin: 4rem auto 0;
`;

const HeroSP = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 1rem;

  > svg {
    width: 75%;
    margin-top: 3rem;
  }
`;

const HeroText = styled.div`
  position: relative;
  width: 30%;

  > h2 {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  > svg {
    position: absolute;
    left: 2rem;
    top: 3rem;
    width: 100%;
    z-index: 2;
  }
`;

const HeroImagePC = styled.div`
  width: 70%;
`;

export default AboutHero;
