import React from 'react';
import styled from 'styled-components';
import { color, font, mediaQuery } from '../styles/variables';
import OutlinedButton from '../components/common/OutlinedButton';
import { Meta } from '../components/common/Meta';

const ForMinorsPage = () => {
  return (
    <Container>
      <Meta title={'18歳未満の方へ'} />
      <Message>18歳未満の方は保護者様の同意が必要となります</Message>
      <Description>
        18歳未満の方が施術を受けられる際、保護者様の同意が必要です。
        <br />
        受診時に同伴できない場合には、このページより同意書のPDFをダウンロードし、ご署名の上ご持参ください。
      </Description>
      <NotesHead>注意事項</NotesHead>
      <Notes>
        <li>親権者1名（一般に両親のうちいずれか）の同意書が必要です。</li>
        <li>同意書は1施術あたり1枚必要です。複数の施術を受ける場合は、施術の数だけ同意書をご用意ください。</li>
        <li>直筆で必ずご記入ください。直筆以外のご記入は無効となります（パソコン等）｡</li>
        <li>鉛筆ではなく、ボールペン等で必ずご記入ください｡</li>
        <li>万が一、同意書のご記入に不備があった場合は、治療をお受けいただくことができません。</li>
      </Notes>
      <DownloadLink href={'/assets/保護者同意書.pdf'} download="保護者同意書.pdf">
        <OutlinedButton label={'同意書（PDFのダウンロード）'} />
      </DownloadLink>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  padding: 5rem 1rem;
  color: ${color.grey[900]};
  font-family: ${font.sansSerif};

  @media screen and ${mediaQuery.mobile} {
    padding: 3rem 1rem;
  }
`;

const Message = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.15em;
  margin-bottom: 2rem;

  @media screen and ${mediaQuery.mobile} {
    font-size: 1rem;
    text-align: left;
  }
`;

const Description = styled.p`
  font-size: 0.9rem;
  line-height: 1.8;
  letter-spacing: 0.1em;
  margin-bottom: 3rem;
`;

const NotesHead = styled.p`
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.2em;
`;

const Notes = styled.ul`
  margin: 1rem 0;
  padding: 0 0 0 1rem;

  > li {
    font-size: 0.9rem;
    line-height: 1.5;
    letter-spacing: 0.1em;
  }

  > li + li {
    margin-top: 0.5rem;
  }
`;

const DownloadLink = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
  max-width: 30rem;
  color: ${color.grey[900]};
  margin: 3rem auto 0;
`;

export default ForMinorsPage;
