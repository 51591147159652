import React, { useRef } from 'react';
import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';
import styled from 'styled-components';

const NavigationLink = (props: AriaButtonProps) => {
  const { children } = props;
  const ref = useRef(null);
  const { buttonProps } = useButton(
    {
      ...props,
    },
    ref
  );
  return (
    <Container {...buttonProps} ref={ref}>
      {children}
    </Container>
  );
};

const Container = styled.a``;

export default NavigationLink;
