import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { color, mediaQuery } from '../../styles/variables';
import { useMediaQuery } from 'react-responsive';

const InstagramBanner = () => {
  const isMobile = useMediaQuery({
    query: mediaQuery.mobile,
  });
  return (
    <Container>
      <LinkArea href="https://www.instagram.com/clinicfor_beauty" rel="noreferrer noopener">
        <SubText>
          <p>
            「すぐ治したい肌トラブルの対処法」や
            {isMobile && <br />}
            「自分に合った美容施術の選び方」など、
            <br />
            うつくしい毎日をサポート！
          </p>
        </SubText>
        <Main>
          <ImageContainer>
            <StaticImage src={'../../images/top/instagram-icon.png'} alt={'instagram'} />
          </ImageContainer>
          <p>
            かかりつけの、美容皮膚科
            <br />
            クリニックフォアビューティーの
            {isMobile && <br />}
            美容マガジン
          </p>
        </Main>
        <LinkText>https://www.instagram.com/clinicfor_beauty</LinkText>
      </LinkArea>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 10rem auto 0;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
`;

const LinkArea = styled.a`
  text-decoration: none;
  color: ${color.black};
`;

const SubText = styled.div`
  line-height: 2;
  text-align: center;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;

  > p {
    display: inline-block;
    padding: 0 2rem;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 4px;
      height: 60px;
      background-color: black;
      transform: rotate(-20deg);
      top: 0;
      left: 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 4px;
      height: 60px;
      background-color: ${color.black};
      transform: rotate(20deg);
      top: 0;
      right: 0;
    }
  }

  @media screen and ${mediaQuery.tablet} {
    font-size: 0.75rem;
    padding: 0 1.2rem;
  }
`;

const Main = styled.div`
  margin: 3rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    margin-left: 2rem;
    font-size: 1.3rem;
    letter-spacing: 0.1em;
  }

  @media screen and ${mediaQuery.tablet} {
    margin: 1rem 0 0;
    > p {
      font-size: 0.8rem;
      margin-left: 0.8rem;
    }
  }
`;

const ImageContainer = styled.div`
  flex: 0 0 80px;

  @media screen and ${mediaQuery.tablet} {
    flex: 0 0 36px;
  }
`;

const LinkText = styled.div`
  margin: 1rem 0 0;
  text-align: center;

  @media screen and ${mediaQuery.tablet} {
    font-size: 0.75rem;
  }
`;

export default InstagramBanner;
