import React from 'react';
import styled from 'styled-components';
import Logo from '../Logo';
import { mediaQuery } from '../../../styles/variables';

const NavigationLogo: React.VFC = () => {
  return (
    <Container>
      <Logo />
    </Container>
  );
};

const Container = styled.div`
  width: 10rem;

  @media screen and ${mediaQuery.tablet} {
    width: 8rem;
  }
`;

export default NavigationLogo;
