import React from 'react';
import styled from 'styled-components';
import AboutHero from '../components/about/AboutHero';
import AboutConcept from '../components/about/AboutConcept';
import AboutDoctors from '../components/about/AboutDoctors';
import { Meta } from '../components/common/Meta';
import FloatingButton from '../components/common/FloatingButton';

const AboutPage = () => {
  return (
    <Container>
      <Meta title={'わたしたちについて'} />
      <AboutHero />
      <AboutConcept />
      <AboutDoctors />
      <FloatingButton visible={true} />
    </Container>
  );
};

const Container = styled.div`
  padding: 8rem 0;
`;

export default AboutPage;
