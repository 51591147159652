import React from 'react';
import styled from 'styled-components';
import { MenuTreatmentProps } from './TreatmentList';
import { color, mediaQuery } from '../../../styles/variables';

type Props = Pick<MenuTreatmentProps, 'contentId' | 'introduction'>;

const Introduction: React.VFC<Props> = ({ contentId, introduction }) => {
  return (
    <>
      {introduction.map((item, i) => (
        <Container key={`Introduction-${contentId}-${i}`}>
          {item.head && <IntroductionHead>{item.head}</IntroductionHead>}
          {item.content && <p>{item.content}</p>}
          {item.whiteningContent && (
            <WhiteningContent>
              <p>{item.whiteningContent.firstLine}</p>
              <WhiteningList>
                {item.whiteningContent.list.map((whiteningList, whiteningIndex) => (
                  <li key={`whiteningContent-${whiteningIndex}`}>{whiteningList}</li>
                ))}
              </WhiteningList>
              <p>{item.whiteningContent.secondLine}</p>
            </WhiteningContent>
          )}
        </Container>
      ))}
    </>
  );
};

const Container = styled.div`
  margin: 2rem 0 0;
  font-size: 0.9rem;
  line-height: 2;

  @media screen and ${mediaQuery.laptop} {
    font-size: 0.8rem;
  }
`;

const IntroductionHead = styled.span`
  background-color: ${color.black};
  display: inline-block;
  padding: 0.1rem 0.8rem;
  margin: 0 0 0.5rem;
  color: ${color.white};
`;

const WhiteningContent = styled.div`
  font-size: 0.9rem;
  line-height: 1.8;
`;

const WhiteningList = styled.ul`
  display: grid;
  list-style: none;
  padding: 0 0 0 1.5rem;
  margin: 1rem 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;

  > li {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: ${color.black};
      border-radius: 50%;
      top: 0;
      bottom: 0;
      margin: auto;
      left: -1rem;
    }
  }

  @media screen and ${mediaQuery.laptop} {
    grid-template-columns: 1fr;
  }
`;

export default Introduction;
